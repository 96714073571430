<template>
  <Transition mode="out-in">
      <!-- :class="{'overflow-relative' : is_overflow}" -->
    <div v-if="local_form && !local_form.loading" class="pb-4 " 

      >
      <!-- {{is_overflow}} aaa -->
      <div class="form-name">
        <a class="is-small mt-2 text-red-500" @click.prevent="$emit('close')" v-if="closes_form">
          <i class="fa-solid fa-times mr-1"></i> 
        </a>
        <span v-html="local_form.name"></span>
      </div>
      <div class="form-field" v-for="(field, idx) in local_form.fields.filter(f => Array.isArray(f) ? f.length > 0 : true)" 
        :key="field.name + ' ' + idx">
          <div class="is-flex is-form 
									is-justify-content-space-between 
									is-flex-direction-row" v-if="Array.isArray(field)">
          <component v-for="(subfield, idxx) in field" :key="subfield.name + '_' + idxx" :is="subfield.type"
            :fields="local_form.fields" :field="subfield" @syncField="syncField" @reset="reset">
          </component>
          </div>
        <component v-else :is="field.type" :field="field" :fields="local_form.fields" @syncField="syncField" >
        </component>
      </div>

        <!-- :class="[is_overflow ? 'absolute bottom-0 right-0' : '']" -->
      <div class="flex justify-between items-center px-4 pt-2"
        >
        <a class="button is-small mt-2 bg-blue-200 text-black-300 bg-orange-200"
          v-if="updated" @click.prevent="resetAll()">
          <i class="fa-solid fa-clock-rotate-left mr-1"></i> Rikthe te dhenat fillestare
        </a>
        <a class="button hover:text-red-900 is-small mt-2 text-white uppercase bg-red-500 mr-2" @click.prevent="deleteEntity()"
          v-if="local_form.delete" href="">
          <i class="has-text-danger-light mr-2 fa fa-trash"></i> Fshi
        </a>
        <a class="button hover:text-green-900 is-small mt-2 has-text-white has-text-weight-bold bg-green-700"
          v-if="isRequiredFilled" @click.prevent="$emit('submitted', form2Object())">
          <i class="fa-solid fa-check mr-1"></i>
          {{ local_form.submit.label.toUpperCase() }}
        </a>
      </div>
      <div class="required-fields text-red-900 flex justify-between items-center bg-orange-200 p-2 mt-4" v-if="!isRequiredFilled">
        <i class="fa-solid fa-exclamation-triangle mr-1"></i>
        Kujdes! Plotesoni te gjitha fushat e detyrueshme
      </div>
      <!-- <pre> -->
      <!-- 	{{local_form.fields}} -->
      <!-- </pre> -->
    </div>
    <div v-else-if="local_form && local_form.loading">
      <div class="has-text-centered">
        <i class="fa-solid fa-spinner fa-spin"></i> Po ngarkohet...
      </div>
    </div>
  </Transition>
</template>

<script>
/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
import Api from '@/services/Api'
import textField from "./fields/text.vue";
import iconField from "./fields/icon.vue";
import numberField from "./fields/number.vue";
import dateField from "./fields/date.vue";
import imageUrl from "./fields/imageUrl.vue";
import emailField from "./fields/email.vue";
import Button from "./fields/button.vue";
import checkboxField from "./fields/checkbox.vue";
import textareaField from "./fields/textarea.vue";
import datetime from "./fields/datetime.vue";
import selectField from "./fields/select.vue";
import treeSelect from "./fields/tree-select.vue";
import multiplexer from "./fields/multiplexer.vue";
import attributes from "./fields/attributes.vue";
import passwordField from "./fields/password.vue";
import moment from 'moment';

export default {
  components: {
    textField,
    iconField,
    numberField,
    dateField,
    imageUrl,
    emailField,
    Button,
    checkboxField,
    textareaField,
    selectField,
    treeSelect,
    multiplexer,
    attributes,
    datetime,
    passwordField
  },
  data() {
    return {
      local_form: null,
      file_name: ''
    };
  },
  methods: {
    deleteEntity() {
      if (confirm(this.local_form.delete.text ?? 'Jeni te sigurte?')) {
        Api(true).post(this.local_form.delete.api, { guid: this.local_form.delete.guid })
          .then(r => {
            this.$emit('deleted', r.data)
          })
      }
    },
    resetAll() {
      this.local_form = { ...this.form }
    },
    reset(fi) {
      var field = {}
      this.form.fields.map(f => {
        if (f.length) {
          f.map(ff => {
            if (ff.name == fi.name) field = ff
          })
        }
        else {
          if (f.name == fi.name) field = f
        }
      })
      this.local_form.fields = [...this.local_form.fields.map(f => {
        if (f.length) {
          f = [...f.map(ff => {
            if (ff.name == field.name) ff = { ...field }
            return ff
          })]
        }
        else {
          if (f.name == field.name) f = { ...field }
        }
        return f
      })]
    },
    requiredFilled() {
      var valid = true
      this.local_form.fields.map(f => {
        if (f.length) {
          f.map(ff => {
            if (ff.type == 'tree-select') {
              if (ff.required && Object.keys(ff.selected_options).length == 0)
                valid = false
            }
            else if (ff.type == "multiplexer") {
              if (ff.required) {
                if (ff.data.length == 0) valid = false
                else {
                  var at_least_one_not_empty = false
                  ff.data.map(d => {
                    if (d.value != "") at_least_one_not_empty = true
                  })
                  valid = at_least_one_not_empty
                }
              }

            }
            else if (ff.unique) {
              if (ff.exists == 1) valid = false
            }
            else
              if (ff.required && (!ff.value || ff.value == "")) valid = false
          })
        }
        else {
          if (f.type == 'tree-select') {
            if (f.required && Object.keys(f.selected_options).length == 0) valid = false
          }
          else if (f.type == "multiplexer") {
            if (f.required) {
              if (f.data.length == 0) {
                valid = false
              }
              else {
                var at_least_one_not_empty = false
                f.data.map(d => {
                  if (d.value != "") at_least_one_not_empty = true
                })
                valid = at_least_one_not_empty

              }
            }
          }

          else if (f.unique) {
            if (f.exists == 1) valid = false
          }
          else {
            if (f.required && (!f.value || f.value == "")) valid = false
          }
        }
      });
      return valid
    },
    form2Object() {
      var obj = {};
      this.local_form.fields.map(f => {
        if (f.length) {
          f.map(ff => {
            if (ff.type == 'tree-select') {
              if (ff.mode == 'single') {
                let vvvv = Object.keys(ff.selected_options)
                obj[ff.name] = vvvv.length == 1 ? vvvv[0] : 'EMPTY-STRING'
              }
              else if (ff.mode == 'multiple') {
                obj[ff.name] = Object.keys(ff.selected_options)
              }

            }
            else if (ff.type == 'multiplexer') {
              obj[ff.name] = ff.data
            }
            else if (ff.type == 'datetime') {
              obj[ff.name] = moment(ff.value).format('DD/MM/YYYY HH:mm')
            }
            else if (ff.type == 'attributes') {
              obj[ff.name + '_list'] = Object.keys(ff.options).map(k => {
                return ff.options[k]
              })
            }
            else obj[ff.name] = isNaN(ff.value != "" ? ff.value : "EMPTY-STRING")
              ? ff.value : (+ff.value)
          })
        }
        else
          if (f.type == 'tree-select') {
            if (f.mode == 'single') {
              let vvvv = Object.keys(f.selected_options)
              obj[f.name] = vvvv.length == 1 ? vvvv[0] : 'EMPTY-STRING'
            }
            else if (f.mode == 'multiple') {
              obj[f.name] = Object.keys(f.selected_options)
            }

          }
          else if (f.type == 'multiplexer') {
            obj[f.name] = f.data
          }
          else if (f.type == 'attributes') {
            obj[f.name + '_list'] = Object.keys(f.options).map(k => {
              return f.options[k]
            })

          }
          else {
            obj[f.name] = isNaN(f.value != "" ? f.value : "EMPTY-STRING") ? f.value : (+f.value)
            // if(f.type == 'select-field') 
          }
      })
      return obj
    },

    // LOGIC:   
    // Gets called when a field is changed

    async syncField(field) {
      // if (field.unique !== undefined) {
      //   await Api(true).post("unique/" + field.unique.model, {
      //     ...field.unique,
      //     value: field.value,
      //   }).then(resp => {
      //     field = {
      //       ...field,
      //       exists: resp.data.exists
      //     }
      //   })
      // }

      // check if is a dependence for other fields
      var is_dependence = false
      var dependence_data = []
      var is_excluded = false
      var excluded_fields = []

      this.local_form.fields = [...this.local_form.fields.map(f => {
        if (f.length) {
          f = [...f.map(ff => {
            if (ff.name == field.name) ff = { ...field } ///////////////////// update field here!
            // check if this field is a connected to another field
            if (ff.excluded_options && ff.excluded_options.includes(field.name)) {
              is_excluded = true
              excluded_fields.push(ff.name)
            }

            // check if this field is a dependence to another field
            else if (ff.depends_on == field.name) {
              is_dependence = true
              dependence_data.push({
                field: ff.name,
                factor: ff.depends_on,
                url: ff.api_call,
                selected_options: field.selected_options
              })
              console.log('d1', dependence_data)
            }

            // check if the fields have been updated
            ff.updated = false
            this.form.fields.map(fi => {
              if (fi.length) {
                fi.map(ffi => {
                  if (ffi.name == ff.name) {
                    if (ff.type == 'tree-select') {
                      ff.updated = ff.selected_options != ffi.selected_options
                    }
                    else
                      ff.updated = ff.value != ffi.value
                  }
                })
              }
              else {
                if (fi.name == ff.name) {
                  if (ff.type == 'tree-select') {
                    ff.updated = ff.selected_options != fi.selected_options
                  }
                  else
                    ff.updated = ff.value != fi.value
                }
              }
            })
            return ff
          })]
        }
        else {
          if (f.name == field.name)
            f = { ...field }  ///////////////////// update field here!
          else if (f.depends_on == field.name) {
            is_dependence = true
            dependence_data.push({
              field: f.name,
              factor: f.depends_on,
              url: f.api_call,
              selected_options: field.selected_options
            })
            console.log('d2', dependence_data)

          }
        }

        f.updated = false
        this.form.fields.map(fi => {
          if (fi.length) {
            fi.map(ffi => {
              if (ffi.name == f.name) {
                f.updated = f.value != ffi.value
              }
            })
          }
          else {
            if (fi.name == f.name) {
              f.updated = f.value != fi.value
            }
          }
        })

        return f
      })]

      if (is_excluded) { // exclude the options of the other fields connected to this field 
        // get all selected options of the local form
        this.local_form.fields.map(f => {
          if (f.length) {
            f.map(ff => {
              if (excluded_fields.includes(ff.name)) {
                if (!ff.all_options) ff.all_options = ff.options
                if (ff.all_options) ff.options = ff.all_options
                var selected_options = {}
                if (ff.excluded_options)
                  ff.excluded_options.map(ef => {
                    selected_options = { ...selected_options, ...this.getSelectedOptions(ef) }
                  })
                if (Object.keys(selected_options).length > 0) {
                  ff.options = ff.all_options.filter(o => {
                    return !selected_options[o.id]
                  })
                  // exclude also from ff the selected options
                  Object.keys(ff.selected_options).map(o => {
                    if (selected_options[o]) {
                      delete ff.selected_options[o]
                    }
                  })
                }
              }
            })
          }
          else {
            if (excluded_fields.includes(f.name)) {
              if (!f.all_options) f.all_options = f.options
              if (f.all_options) f.options = f.all_options
              var selected_options = {}
              if (f.excluded_options)
                f.excluded_options.map(ef => {
                  selected_options = { ...selected_options, ...this.getSelectedOptions(ef) }
                })
              if (Object.keys(selected_options).length > 0) {
                f.options = f.all_options.filter(o => {
                  return !selected_options[o.id]
                })
                // exclude also from f the selected options
                Object.keys(f.selected_options).map(o => {
                  if (selected_options[o]) {
                    delete f.selected_options[o]
                  }
                })
              }
            }
          }
        })
      }

      //  LOGIC: if there is any dependending fields, 
      //  the dependence data is stored in dependence_data
      //  and the dependening fields are updated in the next step
      //  after the dependence data is fetched from the server 

      if (is_dependence) {
        for (var i = 0; i < dependence_data.length; i++) {
          await Api(true).post(dependence_data[i].url, dependence_data[i])
            .then(r => {
              var options = {}
              r.data.map(d => {
                if (!options[d.attribute_id])
                  options[d.attribute_id] = {
                    root: {
                      guid: d.attribute_id,
                      name: d.attribute_name,
                      type: d.type_id,
                      dependency: Object.keys(dependence_data[i].selected_options)[0],
                      value: ''
                    },
                    children: []
                  }

                options[d.attribute_id].children.push({
                  guid: d.child_attribute_guid,
                  name: d.child_attribute_name
                })
              })
              this.local_form.fields = [...this.local_form.fields.map(f => {
                if (f.length) {
                  f = f.map(ff => {
                    if (ff.name == dependence_data[i].field) ff = { ...ff, options: options }
                    return ff
                  })
                }
                else {
                  if (f.name == dependence_data[i].field)
                    f = { ...f, options: options }
                }
                return f
              })]
            })
        }
      }
    },
    // get selected options of a field from the local form
    getSelectedOptions(field) {
      var selected_options = {}
      this.local_form.fields.map(f => {
        if (f.length) {
          f.map(ff => {
            if (ff.name == field) {
              if (ff.selected_options)
                selected_options = { ...selected_options, ...ff.selected_options }
            }
          })
        }
        else {
          if (f.name == field) {
            if (f.selected_options)
              selected_options = { ...selected_options, ...f.selected_options }
          }
        }
      })
      return selected_options
    },

  },
  watch: {
    'form': {
      handler: function (val, oldVal) {
        // console parameters 
        if (val.loading || (val.name != oldVal.name))
          this.local_form = { ...val }
      },
      deep: true
    }
  },
  computed: {
    updated() {
      var updated_form = false
      if (!this.local_form?.fields) return false
      this.local_form.fields.map(f => {
        if (f.length) {
          f.map(ff => {
            if (ff.updated) updated_form = true
          })
        }
        else {
          if (f.updated) updated_form = true
        }
      })
      return updated_form;
    },
    isRequiredFilled() {
      var valid = true
      if (!this.local_form?.fields) return false
      this.local_form.fields.map(f => {
        if (f.length) {
          f.map(ff => {
            if (ff.type == 'tree-select') {
              if (ff.required && Object.keys(ff.selected_options).length == 0)
                valid = false
            }
            else if (ff.type == "multiplexer") {
              if (ff.required) {
                if (ff.data.length == 0) valid = false
                else {
                  var at_least_one_not_empty = false
                  ff.data.map(d => {
                    if (d.value != "") at_least_one_not_empty = true
                  })
                  valid = at_least_one_not_empty
                }
              }

            }
            else if (ff.unique) {
              if (ff.exists == 1) valid = false
               if (ff.required && !ff.value) valid = false
            }
            else
              if (ff.required && (!ff.value || ff.value == "")) valid = false
          })
          // if any of the fields is not valid, return false
          if(!valid) return false
        }
        else {
          if (f.type == 'tree-select') {
            if (f.required && Object.keys(f.selected_options).length == 0) valid = false
          }
          else if (f.type == "multiplexer") {
            if (f.required) {
              if (f.data.length == 0) {
                valid = false
              }
              else {
                var at_least_one_not_empty = false
                f.data.map(d => {
                  if (d.value != "") at_least_one_not_empty = true
                })
                valid = at_least_one_not_empty

              }
            }
          }

          else if (f.unique) {
            if (f.exists == 1) valid = false
            if (f.required && !f.value) valid = false
          }
          else {
            if (f.required && (!f.value || f.value == "")) valid = false
          }

          // if any of the fields is not valid, return false
          if(!valid) return false
        }
        
      });
      return valid
    },
  },
  created() {
    setTimeout(() => {
      this.local_form = { ...this.form }
    }, 100)

  },
  props: [
    "form",
    "is_overflow",
    "closes_form"
  ],

}
</script>

<style>
.form-name {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  text-align: right;
  border-radius:  2px 2px 0 0;
  padding: 4px 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.bg-success-button {
  background-color: rgb(100, 111, 155);
}

.bg-delete-button {
  background-color: #b58b8b;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


.overflow-relative{
  overflow-y: scroll;
  height: 100%;
  position: relative;
}
/* all text media queries for mobile */

/* make form field zebra style */
.form-field:nth-child(even) {
  background-color: #fafafa;
}
.form-field {
  border-left: 1px solid #bfd4dc;
  border-right: 1px solid #bfd4dc;
  border-bottom: 1px solid #bfd4dc;
  padding: 2px 10px;
}
.field {
  padding: 1px 2px;
}

.field .label {
  font-size: 10px !important;
  text-transform: uppercase;
  font-weight: 500;
  color: #4b7482;
}
.field .select, select, input {
  width: 100%;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.required-fields {
  font-size: 12px;
  border-radius: 2px;
  padding: 4px 10px;
  margin: 0 10px;
}
</style>


