import Api from "@/services/Api";

export default {
    async getCities() {
        var cities = []
        await Api(true).post("city/list", {})
        .then(r => {
            cities = r.data.map(e => {
                e.id = e.guid
                e.label = e.name
                return e
            })
        })
        return cities
    },

    async createCity(city) {
        await Api(true).post("city/create", city)
        .then(r => {
            city = r.data
        })
        return city
    },

    async editCity(city) {
        await Api(true).post("city/edit", city)
        .then(r => {
            city = r.data
        })
        return city
    }
}