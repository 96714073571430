<template>
	<div class="field" :style="field.style" v-if="condition">
		<label class="label">
			{{local_value.label}}
			{{local_value.required ? ' *' : ''}}
		</label>
		<div class="mt-2" >

			<div class="columns" v-for="f in local_value.options" :key="f.id">
				<div class="column is-2 has-text-right">
					{{f.label}}
				</div>
				<div class="column">

					<div class="columns">
						<div class="contact-detail column" v-if="local_value.data" >
							<div class="control" :class="[
													'has-icons-left',
													'has-icons-right'
													]" 
									v-for="c in local_value.data.filter(t => t.type_id == f.id)" :key="c.guid"
													>
								<input class="input is-small" @keyup="$emit('syncField', local_value)"
											v-model="c.value" type="text" :placeholder="f.label + '...'"
									/>
								<span class="icon is-small is-left" >
									<i :class="f.icon"></i>
								</span>
								<span class="icon is-small is-right" >
									<i class="fa fa-trash"></i>
								</span>
							</div>
						</div>
						<div class="column is-1">
							<a href="" @click.prevent="local_value.data.push({
									guid: null, 
									type_id: f.id,
									value: ''
								})">
								<i class="fa fa-plus"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<p class="help is-danger" 
			v-if="local_value.required && local_value.data.length == 0">
			{{ local_value.required_text ? local_value.required_text : 'Fushe e detyrueshme' }}
		</p> 
	</div>
</template>

<script>
	export default{
		props: [
			'field', 'fields'
		], 
		data() {
			return {
				local_value: null
			};
		},
		
		computed: {
			condition(){
				var is_visible = true
				if(this.local_value.vif){
				if(this.fields)
					this.fields.map(f => {
						if(f.length) {
							f.map(ff => {
								this.local_value.vif.map(c => {
									if(ff.name == c.field){
										if(c.value_not !== undefined) 
											is_visible = ff.value != c.value_not
									}
								})
							})
						}
						else {
								this.local_value.vif.map(c => {
									if(f.name == c.field) {
										if(c.value_not !== undefined) {
											is_visible = f.value != c.value_not
										}
										if(c.value !== undefined) {
											is_visible = f.value == c.value
										}

									}
								})
						}
					})
				}
				return is_visible
			},
		},
		watch: {
			'field'(){
				this.local_value = {...this.field}	
			},
			'local_value.data'(){
				this.$emit('syncField', this.local_value)
			}

		},
		created(){
			this.local_value = {...this.field}	
		}
	}
</script>

<style scoped>
.label {
    margin-bottom: 0!important;
		color: grey;
}
</style>

