import { defineStore } from 'pinia'

export const useCityStore = defineStore({
  id: 'city',
  state: () => ({
    rowData: []
  }),
  getters: {
  }, 
  actions: {
  }
})
