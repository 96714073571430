import Api from "@/services/Api";

export default {
  async getPointsOfSale() {
    var pos = [];
    await Api(true)
      .post("point_of_sale/list", {})
      .then((r) => {
        pos = r.data.map(e => {
          e.id = e.guid
          e.label = e.name
          return e
        });
      });
    return pos;
  },

  async createPointOfSale(pos) {
    await Api(true)
      .post("point_of_sale/create", pos)
      .then((r) => {
        pos = r.data;
      });
    return pos;
  },

  async editPointOfSale(pos) {
    await Api(true)
      .post("point_of_sale/edit", pos)
      .then((r) => {
        pos = r.data;
      });
    return pos;
  },

  async getCities() {
    var cities = [];
    await Api(true)
      .post("city/list", {})
      .then((r) => {
        cities = r.data.map((e) => {
          // e.id = e.guid
          e.label = e.name;
          return e;
        });
      });
    return cities;
  },

  async getWarehouses() {
    var warehouses = [];
    await Api(true)
      .post("warehouse/list", {})
      .then((r) => {
        warehouses = JSON.parse(r.data).map((e) => {
          e.id = e.guid;
          e.label = e.name;
          return e;
        });
      });
    return warehouses;
  },
  
  async getPosSalesAgents(pos_guid) {
    var agents = [];
    await Api(true)
      .post("point_of_sale/sales_agent/list", {guid: pos_guid})
      .then((r) => {
        agents = r.data.map((e) => {
          e.id = e.guid;
          e.name = e.agent_name;
          return e;
        });
      });
    return agents;
  },

  async getProducts() {
    var products = []
    await Api(true).post("product/product_list", {})
    .then(r => {
      products = r.data.map(e => {
        e.id = e.guid
        e.label = e.name
        return e
      })
    })
    return products
  },

  async getCategorizations() {
    var cat = []
    await Api(true).post("categorization/list", {})
    .then(r => {
      cat = r.data.map(e => {
        e.label = e.name
        return e
      })
    })
    return cat
  },

  async getUsers() {
    var users = []
    await Api(true).get("user/get/users")
    .then(r => {
      users = r.data.map(e => {
        e.label = e.name
        return e
      })
    })
    return users
  }
};
