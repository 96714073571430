import { defineStore } from 'pinia'
import Api from "@/services/Api";
var numeral = require('numeral');
import moment from 'moment'
import SupplierServices from "@/services/Supplier";
import CustomersServices from "@/services/Customer";
import CustomerCreateForm from "@/pages/customers/forms/create.js";
// import WarehousesServices from "@/services/Warehouse";
// import { inject } from "vue";
// const toast = inject("toast");
// import Toaster from "@incuca/vue3-toaster";


export const useProcessStore = defineStore({
  id: 'process',
  state: () => ({
    processes_list: [],
    process: {},
    processes_invoices: [],
    process_steps: [],
    current_step: {},
    is_new: false,
    customers_search_val: "",
    assigned_pos_list: [],
    selected_pos: null,
    process_documents: [],
    product_list: [],
    warehouses_list: [],
    warehouse_types: [],
    selected_warehouse: {origin: null, destination: null},
    customers_list: [],
    assigned_warehouses_list: [],
    suppliers_list: [],
    product_exclusive_accessories: [],
    product_exclusive_accessories_one: [],
    attributes: {origin: [], destination: []}, 
    documents_filter_status: '0',
    months: [
      {id: 1, name: 'Janar'},
      {id: 2, name: 'Shkurt'},
      {id: 3, name: 'Mars'},
      {id: 4, name: 'Prill'},
      {id: 5, name: 'Maj'},
      {id: 6, name: 'Qershor'},
      {id: 7, name: 'Korrik'},
      {id: 8, name: 'Gusht'},
      {id: 9, name: 'Shtator'},
      {id: 10, name: 'Tetor'},
      {id: 11, name: 'Nentor'},
      {id: 12, name: 'Dhjetor'},
    ],
    documents_filter_month: 0,
    documents_filter_text: '',
    payment_terms: [],
    payment_methods: [],
    service_list: [],
    toggle_scan_modal: false,
    show_services_modal: false,
    selected_item_service: {},
    customer_create_form: null,
    toggleCreateCustomer: false,
    customers_search: false,
    grouped_customers: [],
    new_customer_id: ''
  }),
  getters: {
    process_name() {
      return this.process?.name
    },
    current_user() {
      return JSON.parse(localStorage.user)
    },
    current_step_index() {
      var curr_index = -1;
      this.process_steps.map((s, i) => {
        if (s.guid == this.current_step.guid) {
          curr_index = i
        }
      })
      return curr_index
    },
    document_product_total() {
      var tot = 0
      this.product_list.map(p => {
        tot += p.price * parseFloat(p.amount)
      })
      return tot
    },
    document_services_total() {
      var tot = 0
      this.product_list.map(p => {
        if (p.extra_services) {
          p.extra_services.map(es => {
            tot += parseFloat(es.price)
          })
        }
      })
      return tot
    },
    document_extra_services_total() {
      var tot = 0
      this.service_list.map(s => {
        tot += parseFloat(s.price)
      })
      return tot
    },
    document_overall_total() {
      return this.document_product_total + this.document_services_total + this.document_extra_services_total
    },
    totals() {
      var tot = {
        with_vat: 0,
        no_vat: 0,
        vat: 0,
        // transport: 0,
        // mountable: 0,
        // total_discount: 0,
        overall_total: 0,
        // discount_price: 0,
        has_discount: false,
      };

      tot.with_vat = this.document_product_total

      this.product_list.map(pl => {
        tot.no_vat += parseFloat(pl.price) * parseFloat(pl.amount)
      })
      tot.vat = tot.with_vat - tot.no_vat

      return tot
    },

    airbag_total() {
      var total = 0
      this.product_list.map(p => {
        total += p.warranty_airbag_amount
      })
      return total
    }

  },
  actions: {
    async save_warehouse_change(product){
    //  console.log('product', product)
    var same_location = product.other_warehouse.find(w => w.warehouse_id == product.from_other_warehouse_id)?.same_location
    product.same_location = same_location
    setTimeout(async () =>{
        return await Api(true).post("/processes/save/warehouse/change", { product })
          .then(res => { 
            this.product_list = this.product_list.map(p => {
              if(p.guid == product.guid){
                res.data.product_id = p.guid
                p = res.data
              }
              return p
            })
          })
      }, 200);
    },

    async getOtherWarehousesOf(product){ 
      delete product.loaded_other_warehouse
      product.other_warehouse = []
      await Api(true).post("/processes/get/other/warehouses", { product })
      .then(res => {
        product.other_warehouse = res.data
        product.from_other_warehouse_id = ''
        product.loaded_other_warehouse = true
      })
    },
    async filter_documents(process_guid){
      await Api(true).post("processes/filter/documents", {
        status: this.documents_filter_status, 
        month: this.documents_filter_month, 
        process_id: process_guid, 
      })
      .then(res => {
        this.process_documents = res.data
      }) 
    },
    async get_process_documents(guid) {
      if(this.documents_filter_status != '0' || this.documents_filter_month != 0) 
        return await this.filter_documents(guid)
      else await Api(true).post("processes/get/documents", { process_id: guid })
        .then(res => {
          this.process_documents = res.data
        })
    },
    async get_process_steps(guid, step_id) {
      var steps = []
      var disputes = []
      // Get process list
      // await Api(true).post("processes/list", {})
      // .then(res => {
      //     this.processes_list = res.data
      // })
      //get single process 
      await Api(true).post("processes/data/steps", { process_guid: guid })
        .then(async res => {
          this.process = res.data.process[0]
          steps = res.data['steps']
          disputes = res.data['disputes']
          // Add disputes
          steps = [...steps.map(s => {
            disputes.map(d => {
              if (d.step_id == s.guid) {
                if (!s.disputes) { s.disputes = [] }
                s.disputes.push(d)
              }
            })
            return s
          })]
          this.process_steps = [...steps.sort((a, b) => a.sort_nr - b.sort_nr)]

          // TO DO: If new: set this.current_step 
          if (step_id == 0) {
            this.current_step = this.process_steps[0]
            this.is_new = true
            // console.log('check is_transaction, ', this.current_step, this.process)
          }
          else {
            // get process_guid
            this.get_process_document(step_id)

            // Backup working
            // await Api(true).post("processes/get/document", { document_id: step_id })
            //   .then(r => {
            //     this.process.destination = r.data.destination
            //     this.process.origin = r.data.origin
            //     this.process.document_id = r.data.guid
            //     this.product_list = r.data.items
            //     this.process.next_step = r.data.next_step
            //     this.process.document_data = r.data
            //     this.current_step = { ...this.process_steps.find(s => s.guid == r.data.step_id) }
            //   })

          }
        })
      // Get assigned pos 
      await Api(true).post('/invoice/get/assigned/pos', { user: this.current_user })
        .then(res => {
          this.assigned_pos_list = res.data
        })
        
      // Get only assigned warehouses and warehouse_types of user
      await Api(true).post('/processes/assigned/warehouses', { user: this.current_user })
      .then(res => {
        this.warehouses_list = res.data[0]
        this.warehouse_types = res.data[1]
      })

      // Get suppliers
      this.suppliers_list = await SupplierServices.getSuppliers()
        
      // Get all waregouses, warehouse_types,
      // this.warehouses_list = await WarehousesServices.getWarehouses()
      // All warehouse types (categories) - Backup working
      // this.warehouse_types = await WarehousesServices.getWarehouseFacilities()

    },
    async get_process_document(document_id) {
      await Api(true).post("processes/get/document", { document_id })
        .then(r => {
          this.process.destination = r.data.destination
          this.process.origin = r.data.origin
          this.process.document_id = r.data.guid
          this.product_list = r.data.items.map(e => {
            e.show_exclusive_dropdown = false
            e.show_accessories_dropdown = false
            e.product_id = e.guid
            return e
          })
          this.service_list = r.data.services
          this.process.next_step = r.data.next_step
          this.process.document_data = r.data
          this.current_step = { ...this.process_steps.find(s => s.guid == r.data.step_id) }
        })
        .catch(e => {
          console.log('error', e)
          window.location.href = '/'
        })
    },
    async open_step() {
      return await Api(true).post("/processes/save/document/step", {
        step: this.current_step,
        document: this.process,
        product_list: this.product_list, 
      }).then(() => {
        console.log('here?!?!!!')
        // window.location.reload();
      })
    },
    next_step() {
      return this.process_steps.find(ps => ps.sort_nr == (this.current_step.sort_nr + 1))
    },
    price_filter(price) {
      return numeral(price).format('0,0') + ' Lek'
    },
    // custom_price_filter(price) {
    //   return numeral(price).format('0,0') + ' Lek'
    // },
    amount_filter(price) {
      return numeral(price).format('0,0')
    },
    date_filter(date) {
      return moment(date).format('DD/MM/YYYY hh:mm')
    },

    // Add product to list
    async add_product_to_list(product) {
        // console.log('product', )

      return await Api(true).post("/processes/save/document", {
        pos_list: this.assigned_pos_list,
        process: this.process,
        step: this.current_step,
        product: product
      })
    },

    toggle_product_extras(product) {
      this.product_list.map(p => {
        if (p.guid == product.guid) {
          if (!p.show_extras) p.show_extras = true
          else p.show_extras = false
        }
      })
    },
    async complete_process() {
      return await Api(true).post("/processes/complete", {
        process: this.process,
        step: this.current_step,
        product_list: this.product_list
      })
    },
    save_party(type, party) {

      Api(true).post("/processes/save/party", {
        process: this.process,
        type,
        party
      })
    },

    save_document_party(type, party) {

      return Api(true).post("/processes/save/document/party", {
        process: this.process,
        type,
        party
      })
    },
    async save_process_item(item) {
      var a = await Api(true).post("/processes/save/item", { process: this.process, item })
      await this.update_item(a.data)
      return a
    },

    /* eslint-disable */
    async save_item_service(product, service){
      var data = await Api(true).post("/processes/save/item/service", {product, service})
      await this.update_item(data)
      return data
    },
    async remove_document_service(service, product) {
      if (confirm("Jeni te sigurt qe doni hiqni sherbimin '" + service.service_name + "'?") == true) {
        var data = await Api(true).post("/processes/remove/item/service", { service, product })
        await this.update_item(data)
        await this.get_process_document(this.process.document_id)
        return data
        // Find and delete item backup
        //   .then(res => {
        //     if (res.data.deleted) {
        //       return res.data
        //       // toast.success('Ndryshimi u ruajt me sukses.');
        //       // // Find and delete item_service
        //       // this.product_list = this.processes_list.map(p => {
        //       //   if (product.detail_id == p.detail_id) {
        //       //     p.extra_services_types['g' + service.service_type_id] = p.extra_services_types['g' + service.service_type_id].filter(s => s.guid != service.guid)
        //       //   }
        //       //   return p
        //       // })
        //     }
        // })
      }
    },
    async save_document_service(service, product) {
      var data = await Api(true).post("/processes/save/document/service", { service, product })
      await this.update_item(data)
      return data
    },
    search_service_type(service_type, product, service_in_use) {
      
      Api(true).post("/processes/search/services", { service_type, service_in_use })
        .then(r => {
          if (r.data.length > 0) {
            this.product_list = [...this.product_list.map(p => {
              //find product
              if (p.detail_id == product.detail_id) {
                // create search vars in product
                p.services_search_result = {}
                p.services_search_result[service_type.service_type_id] = []
                // Push and filter results
                r.data.map(rr => {
                  p.services_search_result[service_type.service_type_id].push(rr)
                  p.services_search_result[service_type.service_type_id] = p.services_search_result[service_type.service_type_id].filter(aa => aa.guid != service_in_use.service_id)
                })
              }
              return p
            })]
          }
          else {
            // Toaster 'No results'
          }
      })


      // product.map(p => {
      //   // if(p. == )
      // })

      // for (let i = 0; i < product.services.length; i++) {
      //   // if(prod)
      // }
    },
    get_document_item(document_item) {
      Api(true).post("/processes/get/document/item", { document_item })
        .then(() => {
        // this.product_list = 
      })
    },
    update_item(item) {
      var a = {}
      this.product_list = this.product_list.map(p => {
        if (p.detail_id == item.data.detail_id) {
          p = { ...{} }
          p = { ...item.data }
          a = { ...p }
        }
        return p
      })
      return a
    },
    product_total(p){
      return p.document_item_price * parseFloat(p.amount)
    },
    extras_total(r) {
      var serv_tot = 0
      var acc_tot = 0
      var exc_acc_tot = 0
      if (r.extra_services) {
        r.extra_services.map(es => {
            serv_tot += es.service_price ? parseFloat(es.service_price) : es.price ? parseFloat(es.price) : 0
        })
      }
      if (r.extra_accessories) {
        r.extra_accessories.map(ea => {
            acc_tot += parseFloat(ea.price)
        })
      }
      if(r.extra_exclusive_accessories) {
        r.extra_exclusive_accessories.map(e => {
          exc_acc_tot += parseFloat(e.price)
        })
      }
      var total = serv_tot + acc_tot + exc_acc_tot
      return total
    },

    item_overall_total(p) {
      return this.product_total(p) + this.extras_total(p)
    },
    get_explusive_entity_data(entity, product){
      Api(true).post("/processes/get/exclusive/accessories", { entity, product })
      .then(res => {
        this.product_exclusive_accessories = res.data
      })
    },
    async delete_document_item(item){
      var delete_childs = false
      if (confirm("Jeni te sigurt qe doni te fshini kete produkt?") == true) {

        // console.log('ch of item',  this.product_list.filter(f => f.reference_id == item.detail_id).length)

        if(this.product_list.filter(f => f.reference_id == item.detail_id).length > 0)
          if ( confirm("Ky produkt ka aksesore te bashkangjitur. Doni ti fshini keto aksesore nga dokumenti?") == true) {
            delete_childs = true
          } else {
            delete_childs = false
          }

        return await Api(true).post("/processes/delete/document/item", { item, delete_childs })

      }
    },
    async get_product_accessories(product){
      await Api(true).post("/processes/get/product/accessories", { product })
      .then(res => {
        // this.product_exclusive_accessories_one = res.data[0]
        this.product_list = [...this.product_list.map(p => {
          if(p.detail_id == product.detail_id){
            if(res.data.length > 0){
              p.available_accessories = res.data
            }
            else {
              p.available_accessories = []
            }
          }
          return p
        })]
      })
    },
    async save_pdi_serial(product){
      var toast = {message: ''}
      await Api(true).post("processes/save/serial/number", product)
      .then(res => {
        if (res.data == 1) {
          toast.message = 'success'
          useProcessStore().product_list = [...useProcessStore().product_list.map(prod => {
            if(prod.detail_id == product.detail_id){
              prod.serial_number = product.serial_number
              if (product.serial_number) {
                prod.edit_serial = false
              }
            }
            return prod
          })]
        }
      })
      return toast
    },
    toggle_process_services(product) {
      this.show_services_modal = !this.show_services_modal
      if (this.show_services_modal) {
        this.selected_item_service = product
      } else {
        this.selected_item_service = {}
      }
    },
    async add_item_service(service) {
      // console.log('here?', service, this.selected_item_service)
      var toast = { message: null }
      await Api(true).post("processes/add/item/service", {
        service: service,
        document_item: this.selected_item_service
      })
        .then(res => {
        // insert to product.extra_services
        if (res.data.guid) {
          this.product_list = [...this.product_list.map(p => {
            if (p.detail_id == res.data.detail_id) {
              p = res.data
            }
            return p
          })]
          toast.message = 'success'
        }
      })
      return toast
    },

    async createCustomer(){
      this.toggleCreateCustomer = true
      this.customer_create_form = { loading: true }
      this.customer_create_form = await CustomerCreateForm(false)
    },
    async getCustomers() {
      this.customers_search = false
      this.customers_list = await CustomersServices.getCustomers();
      // group by type_id into grouped_customers
      this.customers_list.map(a => {
        if(!this.grouped_customers.find(b => b.id == a.type_id)){
          this.grouped_customers.push({id: a.type_id, name: a.type_id_name})
        }
      })
    },
    async save_customer_id() {
      await Api(true).post("processes/save/customer/id", {
        customer_id: this.new_customer_id,
        process: this.process
      })
      .then(res => {
        if (res.data == 1) {
          this.process.destination_personal_id = this.new_customer_id
        }
      })
    },
    save_warranty_airbag_amount(product) {
      Api(true).post("processes/save/warranty/airbag", {product})
      .then(res => {
        if (res.data == 1) {
          this.product_list = [...this.product_list.map(p => {
            if (p.detail_id == product.detail_id) {
              if (p.warranty_airbag_amount) p.warranty_airbag_amount = 0
              else p.warranty_airbag_amount = product.warranty_airbag_value
            }
            return p
          })]
        }
      })
    }
  }
})
