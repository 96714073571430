
import { defineStore } from 'pinia'
import Api from "@/services/Api";
import helpers from "@/helpers.js";
var numeral = require('numeral');

export const usePrintStore = defineStore({
    id: 'print',
    state: () => ({
        print_document: {},
        show_create_new: false,
        show_list: true,
        print_document_list: [],
        print_variables: {
            'origin': {
                'value': '',
                'description': 'Some description about the origin tag'
            },
            'destination': {
                'value': '',
                'description': ''
            },
            'doc.nr': '',
            'step.name': '',
            'created.by': '',
            'created.at': '',
            'product.list': {
                'product.name': '',
                'product.amount': '',
                'product.price': '',
                'product.price.total': '',
                'product.qty.total': '',
                'product.qty': '',
            },
        }
    }),
    getters: {},
    actions: {
        async get_print_documents(step, doc_type = null) {
            await Api(true).post("/print/get", {step, doc_type})
                .then(res => {
                this.print_document_list = res.data
            })
        },
        async save_select_step_print(doc, step){
            return await Api(true).post("/print/save/select/document", {doc, step})
        },
        
        get_print_html(content, doc){
            var items = doc?.document_data?.items
            if(!items) return ''
            var keys = {
                '@created_by': this.nameFilter(doc.document_data.created_by_name),
                '@created_at': doc.document_data.created_at,
                '@invoice_id': '#' + doc.document_data.id,
                '@origin_name': doc.document_data.origin_name ? doc.document_data.origin_name : '<i>I Papercaktuar</i>',
                '@destination_name': doc.document_data.destination_name ? doc.document_data.destination_name : '<i>I Papercaktuar</i>', 
                '@document_name': doc.name
            }

            var product_table_columns = this.getSubstringBetween(content, '@product_list', '@product_list_end')
            var orgsubs = product_table_columns
            
            content = content.replace("@product_list", "")
            content = content.replace("@product_list_end", "")
            var has_total_row = content.indexOf("@total_row");
            if(has_total_row > -1) content = content.replace("@total_row", "")
            content = content.replace(orgsubs, "@table")
            keys["@table"] = helpers.get_html_table(product_table_columns, items)

            return this.print_doc_replace_keys(keys, content)
        },
        
        print_doc_replace_keys(keys, content){
            Object.keys(keys).map(k => {
                var regex = new RegExp(k, 'g')
                content = content.replace(regex, keys[k])
            })
            return content
        },
        getSubstringBetween(originalString, startSubstring, endSubstring) {
            var startIndex = originalString.indexOf(startSubstring);
            if (startIndex === -1) {
              return '';
            }
            
            startIndex += startSubstring.length;
            
            var endIndex = originalString.indexOf(endSubstring, startIndex);
            if (endIndex === -1) {
              return '';
            }
            
            return originalString.substring(startIndex, endIndex);
        },

        clean_value(data){
            if(!data) return ''
            else return data
            
        },
        nameFilter(name){
            var firstLetter = name.slice(0, 1).toUpperCase()
            var remainingLetters = name.slice(1)
            var finalName = firstLetter + remainingLetters
            return finalName 
        },
        dateFilter(date) {
            var a = date.split(' ')
            var b = a[0]
            b = b.split('-')
            b = b[2] + '/' + b[1] + '/' + b[0]
        
            return b
        },
        // amountFormat(amount) {
        //     var a = amount.split('.')
        //     a = parseInt(a)
        //     return a
        // },
        priceFormat(price){
            return numeral(price).format('0,0') + ' Lek'
        },
        print_entity(content){
            var a = window.open('', '', 'height=1000, width=1000');
            a.document.write('<html>');
            a.document.write('<body >');
            a.document.write('<style>')

            a.document.write(" .is-fullwidth { width: 100%; } .table { display: table; } .has-text-right { text-align: right; } .table { font-family: Arial, Helvetica, sans-serif; border-collapse: collapse; width: 100%; } .table td { border: 1px solid #ddd; } .table th { border: 1px solid #ddd; }}")
            
            a.document.write('</style>')
            a.document.write(content);
            a.document.write('</body></html>');

            a.document.close();
            a.onbeforeunload = function() {
                a.close();
            };

            a.onload = function() {
                a.print();
                setTimeout(function() {
                a.close();
                }, 100);
            };
        }
    }
})