import Api from "@/services/Api";
export default{
	async getCustomers(){
		let customers = []	
    await Api(true)
      .post("customer/list", {})
      .then((response) => {
        customers = response.data.map((c) => {
          if(c.firstname === null && c.lastname === null) {
            c.name = c.business_name
          }
          else {
            c.name = c.firstname + " " + c.lastname
          }
          c.id = c.guid
          c.label = c.name
          return c;
        });
      });
		return customers;
	},
  async createCustomer(customer){
    await Api(true)
      .post("customer/create", customer)
      .then((response) => {
        response.data.name =
          response.data.firstname + " " + response.data.lastname;
        customer = response.data;
      });
    return customer;
  },
  async updateCustomer(customer){
    await Api(true)
      .post("customer/edit", customer)
      .then((response) => {
        response.data.name =
          response.data.firstname + " " + response.data.lastname;
        customer = response.data;
      });
    return customer;
  },
  async get(p){
      var customer = {}
      await Api(true).post("customer/details", p)
      .then(r => {    
          customer = r.data
      })
      return customer;
  },

  async getDistricts() {
    var districts = []
    await Api(true).post("districts/list", {})
    .then(r => {
      districts = r.data.map(e => {
        // e.id = e.guid
        e.label = e.name
        return e
      })
    })
    return districts
  },

  async getRegions() {
    var regions = []
    await Api(true).post("regions/list", {})
    .then(r => {
      regions = r.data.map(e => {
        // e.id = e.guid
        e.label = e.name
        return e
      })
    })
    return regions
  }, 

  async getCustomerRefferrals(){
    var refferrals = [] 
    await Api(true).post("customer/refferrals", {})
    .then(r => {
      refferrals = r.data.map(e => {
        e.label = e.name
        return e
      })
    })
    return refferrals
  }
}
