<template>
  <div class="field" :style="field.style" v-if="condition" style="text-align: center">
    <label class="label">
      <span v-if="!local_value.label_icon">
        {{ local_value.label }}
      </span>
      <span v-else>
        <i :class="local_value.label_icon"></i>
      </span>

      {{ local_value.required ? ' *' : '' }}
    </label>
    <div class="control has-text-centered" :class="[
      local_value.left_icon ? 'has-icons-left' : '',
      local_value.right_icon ? 'has-icons-right' : ''
    ]">
      <input type="checkbox" true-value="1" false-value="0" @change="$emit('syncField', local_value)"
        v-model="local_value.value" :name="local_value.name" />
      <span class="icon is-small is-left" v-if="local_value.left_icon">
        <i :class="local_value.left_icon"></i>
      </span>
      <span class="icon is-small is-right" v-if="local_value.right_icon">
        <i :class="local_value.right_icon"></i>
      </span>
    </div>
    <!-- <p class="help is-danger">This is invalid</p> -->
  </div>
</template>

<script>
export default {
  props: [
    'field', 'fields'
  ],
  data() {
    return {
      local_value: null
    };
  },

  computed: {
    condition() {
      var is_visible = true
      if (this.local_value.vif) {
        if (this.fields)
          this.fields.map(f => {
            if (f.length) {
              f.map(ff => {
                this.local_value.vif.map(c => {
                  if (ff.name == c.field) {
                    if (c.value_not !== undefined)
                      is_visible = ff.value != c.value_not
                  }
                })
              })
            }
            else {
              this.local_value.vif.map(c => {
                if (f.name == c.field) {
                  if (c.value_not !== undefined) {
                    is_visible = f.value != c.value_not
                  }
                  if (c.value !== undefined) {
                    is_visible = f.value == c.value
                  }

                }
              })
            }
          })
      }
      return is_visible
    }
  },

  created() {
    this.local_value = { ...this.field }
  }
}
</script>

<style scoped>
.label {
  margin-bottom: 0 !important;
  color: grey;
}
</style>

