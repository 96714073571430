import { defineStore } from "pinia";
import Api from "@/services/Api";
import Customer from "@/services/Customer";

export const useCreditnoteStore = defineStore({
  id: "creditnote",
    state: () => ({
        creditnote_list: [],
        show_create_new: false,
        new_creditnote: {},
        customers: [],
        show_customers_list: false,
        search_customer_val: '',
        search_creditnote_val: '',
        show_create_creditnote: false,
        creditnote_types: [],

    }),
    getters: {
        filtered_customers() {
            const regex = new RegExp(this.search_customer_val, 'i');
            return this.customers.filter(item => regex.test(item.name));
        },
        filtered_creditnotes() {
            if (this.search_creditnote_val == "") return this.creditnote_list;
            return this.creditnote_list.filter((row) => {
                return Object.keys(row).some((key) => {
                return String(row[key])
                    .toLowerCase()
                    .includes(this.search_creditnote_val.toLowerCase());
                });
            });
        },
        can_create_creditnote() {
            var can_create = false
            if (
                this.new_creditnote.customer?.guid &&
                this.new_creditnote.amount && 
                this.new_creditnote.creditnote_type
            ) {
                can_create = true
            }
            return can_create
        }
    },
    actions: {
        async get_creditnotes() {
            await Api(true).post("/creditnote/get")
            .then(res => {
                this.creditnote_list = res.data.credit_notes
                this.creditnote_types = res.data.types
            })
        },
        async toggle_create_new() {
            this.show_create_creditnote = !this.show_create_creditnote
            this.new_creditnote = {}
            // get customer list
            if (this.show_create_creditnote) {
                this.customers = await Customer.getCustomers();
                
            }
        },
        select_customer(customer) {
            this.new_creditnote.customer = {}
            this.new_creditnote.customer = {...customer}
            this.search_customer_val = ''
        },
        async create_new_creditnote() {
            var toast = null
            await Api(true).post("/creditnote/create", {
                credit_note: this.new_creditnote
            })
            .then(res => {
                this.creditnote_list.unshift(res.data[0])
                this.show_create_creditnote = false
                this.new_creditnote = {}
                if (res.data[0].guid) {
                    toast = {message: 'success'}
                }
            })
            return toast
        }

    }
})
