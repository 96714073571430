import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";
// import GenericServices from "@/services/Generic";
import PointsOfSaleServices from "@/services/PointOfSale";
import CustomersServices from "@/services/Customer";

export default async function createCustomer(extended = true) {

  var customer_categories = await CategoriesServices.getCategories("customers");
  var customer_types = helpers.toTree(customer_categories, "id")
  // var contact_details = extended ? await GenericServices.getDetailsTypes({ related_entity: "customer" }) : [];
  var city = await PointsOfSaleServices.getCities()
  
  var region = extended ? await CustomersServices.getRegions() : []
  var district = extended ? await CustomersServices.getDistricts() : []
  var referrals = await CustomersServices.getCustomerRefferrals() 

  return {
    name: 'Krijo klient',
    submit: {
      label: 'Krijo',
      action: 'submitCustomer'
    },
    fields: [
      [
        {
          value: '',
          name: 'code',
          label: 'Kodi',
          type: 'text-field',
          style: "min-width: 30%",
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme',
          unique: {
            model: 'customer',
            field: 'code',
            where: []
          },
          left_icon: 'fa-solid fa-hashtag',
        },
        {
          value: '',
          name: 'firstname',
          label: 'Emri',
          type: 'text-field',
          style: 'min-width: 30%',
          required: true,
        },
        {
          value: '',
          name: 'lastname',
          label: 'Mbiemri',
          type: 'text-field',
          style: 'min-width: 30%',
          required: true,
        },
        {
          value: 1,
          name: 'active',
          label: 'Aktiv',
          type: 'checkbox-field',
          stle: "min-width: 2%"
        },
      ],
      [
        {
          value: '',
          name: 'phone',
          label: 'Telefoni',
          type: 'text-field',
          style: 'min-width: 100%'
        },
      ],
      [
        {
          value: '',
          name: 'phone',
          label: 'Telefoni',
          type: 'text-field',
          style: 'min-width: 100%'
        },
      ],
      [
        {
          value: '',
          name: 'location',
          label: 'Adresa',
          type: 'text-field',
          style: 'min-width: 50%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: '',
          name: 'city',
          label: 'Qyteti',
          type: 'select-field',
          options: city,
          style: 'min-width: 50%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        }
      ],
      [
        {
          value: '',
          name: 'personal_id',
          label: 'ID Personale',
          type: 'text-field',
          style: 'min-width: 48%;',
          // required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        }, 
        {
          value: '',
          name: 'dob',
          label: 'Datelindja',
          type: 'date-field',
          style: 'min-width: 48%'
        },
      ],
      [
        {
          value: extended ? '' : 'pos',
          options: [
            // { id: '', label: 'Zgjidh...' },
            { id: 'web', label: 'Web' },
            { id: 'pos', label: 'POS' },
            { id: 'agent', label: 'Agjent' },
            { id: 'customer', label: 'Klient' },
            { id: 'other', label: 'Tjeter' }
          ],
          name: 'registration_channel',
          label: 'Kanali i regjistrimit',
          type: 'select-field',
          style: 'min-width: 50%',
          required: false,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: '',
          name: 'referred_by',
          label: 'Referuar nga',
          type: 'select-field',
          style: 'min-width: 50%;', 
          options: referrals,
        },
      ],
      [
        {
          value: 1,
          options: [
            { id: 1, label: 'Pakice' },
            { id: 2, label: 'Shumice' },
          ],
          name: 'sales_type',
          label: 'Kanali i regjistrimit',
          type: 'select-field',
          style: 'min-width: 50%',
          required: false,
        },
        {
          value: 0,
          name: 'is_default',
          label: 'Klient default i sistemit',
          type: 'checkbox-field',
        },
      ],
      [
        { 
          value: '',
          name: 'other_referral', 
          label: 'Kanali tjeter',
          type: 'text-field',
          style: 'min-width: 100%',
          vif: [ 
            {field: 'referred_by', value: 6}
          ]
        }
      ],

      extended ? [
        {
          value: '',
          name: 'region',
          label: 'Rajoni',
          type: 'select-field',
          options: region,
          style: 'min-width: 50%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: '',
          name: 'district',
          label: 'Rrethi',
          type: 'select-field',
          options: district,
          style: 'min-width: 50%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        }
      ] : [],
      [
        {
          name: 'type_id',
          label: 'Kategoria',
          type: 'tree-select',
          style: 'flex: 1',
          options: customer_types,
          selected_options: [],
          mode: 'single',
        },
      ],
      [
        {
          data: [],
          name: 'attributes',
          label: '',
          type: 'attributes',
          options: [],
          style: 'min-width: 100%',
          depends_on: 'type_id',
          api_call: 'category/attributes'
        },
      ],

    ]
  }
}
