<template>
	<div>
		<div v-for="(parent, idx) in local_value" :key="idx" 
			:class="[
					!parent.children ? 'has-border-bottom' : '', 
					(parent.children && !parent.expand) ?	'has-border-bottom' : ''
			]" >
			<div :style="{'padding-left': ((i)*20)+'px'}" >
				<a @click.prevent="selectedvalue = parent" v-if="!parent.children || field?.select_root" class="mr-1">
					<i class="fa" 
						:class="[
							selected_options[parent.id] ? 'fa-square-check' : 'fa-square has-text-grey-light'
						]"></i>
				</a>
				<div v-else style="width: 17px;  
						height: 13px; 
						display: inline-block;" >
					&nbsp;
				</div>
				<a 
					class="item-tree" href="" @click.prevent="collapse(parent)">
					<i class="fa is-size-7 mr-2 mt-1 is-pulled-right" 
						:class="{
							'fa-caret-down': (!parent.expand), 'fa-caret-up': parent.expand, 
						}"
						v-if="parent.children"></i>
					<span :class="{'has-text-success-dark': Object.keys(selected_options).includes(parent.id)}">
						{{parent.label}}
					</span>
				</a>
			</div>
			<div class="children-tree">
				<div v-if="parent.expand && parent.children"
					class="has-border-top" >
					<tree-options 
						:root_value="root_value" :i="i + 1"
						:selected_options="selected_options"
						:inherited_selected_options="inherited_selected_options"
						:options="parent.children"
            :field="field"
          ></tree-options>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import treeOptions from './tree-option'
	import { inject } from "vue";
	export default{		
		name: 'tree-options',
		components: {
			treeOptions
		},
		setup() {
			return {
				selectedvalue: inject('selectedvalue'),
			}
		},
		data() {
			return {
				local_value: [],
				selected: false,
			};
		},
		watch: {
			options: {
				handler: function (val) {
					// this.local_value = [...val];
					this.checkforinheritance(val)
				},
				deep: true,
			},
		},
		props: ["options", "i", "root_value", "selected_options","inherited_selected_options", "field" ],
		methods: {
			collapse(p){
				if(!p.children) {
					p.value = p.id
					this.selectedvalue = p
				}	
				this.local_value = [...this.local_value.map(e => {
					if(e.id == p.id) { 
						e.expand = !e.expand
					}
					return e
				})]
			},
			checkforinheritance(options){
				if(this.inherited_selected_options)
					this.local_value = [...options.filter(o => {
						var include = true
						this.inherited_selected_options.map(io => {
							if(io.accessory_id == o.id) { 
								include = false
							}
						})
						return include
					})]
				else this.local_value = [...options]
			}
		},
		created(){
			this.checkforinheritance(this.options)
		}
	}
</script>

<style scoped>
.children-tree{
	display: flex; 
	flex-direction: column;
}
.item-tree{
	flex: 1 1 0px;
	width: 100%;
}
.has-border-bottom {
	border-bottom: 1px solid #dbdbdb;
}
.has-border-top {
	border-top: 1px solid #dbdbdb;
}
</style>

