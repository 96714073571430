import { defineStore } from 'pinia'
import Api from "@/services/Api";
import { useMenuStore } from '@/stores/menu'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
		is_loggedin: false,
		user: {},
		session_check: -1,
  }),
  getters: {
  }, 
  actions: {
    async get_user_notifications(user){
      await Api(true) .post("/user/get/notifications", { user })
      .then(res => {
        Object.keys(res.data).map((key) => {
          if(!useMenuStore().notifications_list[key]){useMenuStore().notifications_list[key] = []}
          useMenuStore().notifications_list[key] = res.data[key]
        })
        localStorage.notifications_list = JSON.stringify(useMenuStore().notifications_list);
      })
    }
  }
})
