<template>
  <div class="field pt-2" :style="field.style" v-if="condition">
    <label class="label is-flex is-align-items-center ">
      {{ local_value.label }}
      {{ local_value.required ? " *" : "" }}
      <a class="ml-4" @click.prevent="undoSelected()">
        <i title="Hiq te selektuarin" class="fa-sharp fa-solid fa-arrow-rotate-left"></i>
      </a>
      <input type="text" placeholder="kerko..." class="input is-small ml-2" v-model="search"  v-if="expand"/>
      <a class="is-pulled-right text-red-600 border-red-100 px-2 ml-1 border border-solid" v-if="expand" @click.prevent="resetValue()" title="Mbyll listen...">x</a>
    </label>
    <a v-if="!expand" class="border-2 border-blue-200 is-block p-1" href="" @click.prevent="resetValue()">
      <ul v-if="local_value.selected_options">
        <li v-for="so in Object.keys(local_value?.selected_options)?.filter((k) => local_value.selected_options[k])"
          :key="so">
          <i class="fa fa-check mr-1 text-green-700"></i>
          <span class="text-green-800 is-uppercase">
            {{ local_value.selected_options[so] }}
          </span>
        </li>
        <li v-if="Object.keys(local_value.selected_options)?.length == 0" class="is-italic has-text-grey ml-2">
          Zgjidhni...
        </li>
      </ul>
    </a>
    <div class="control border-2 border-blue-200" style="max-height: 150px; overflow-y: scroll" v-if="expand"
      :class="[local_value.left_icon ? 'has-icons-left' : '', local_value.right_icon ? 'has-icons-right' : '',]">
      <div class="is-small p-1">
        <div v-for="(parent, idx) in local_value.options.filter(a => JSON.stringify(a).toLowerCase().includes(search.toLowerCase()))" :key="parent.id">
          <div :class="{
            'has-border-bottom': idx != local_value?.options?.length - 1,
          }" class="is-block">
            <a v-if="!parent.children || field?.select_root" class="mr-1" @click.prevent="setSelected(parent)">
              <i class="fa mr-1" :class="[
                local_value.selected_options[parent.id] ? 'fa-square-check' : 'fa-square has-text-grey-light',
              ]"></i>
              <i class="fa mr-1 fa-rectangle" :style="{
                background: field.isColor == true ? parent.name : '',
                height: field.isColor == true ? '18px' : '',
                width: field.isColor == true ? '70%' : '',
              }" :class="field.isColor == true ? 'mt-2' : ''"></i>
            </a>
            <div v-else style="width: 20px; height: 13px; display: inline-block">
              &nbsp;
            </div>
            <a href="" @click.prevent="collapse(parent)">
              <i class="fa is-size-7 mr-2 mt-1 is-pulled-right" :class="{
                'fa-caret-down': !parent.expand,
                'fa-caret-up': parent.expand,
              }" v-if="parent.children"></i>

              <span :class="{ 'has-text-success-dark': Object.keys(local_value.selected_options).includes(parent.id), }">
                {{ field.isColor === true ? "" : parent.label }}
              </span>
            </a>
          </div>
          <div v-if="parent.expand && parent.children">
            <tree-options :root_value="local_value.root_value" :i="1" :selected_options="local_value.selected_options"
              :inherited_selected_options="local_value.inherited_selected_options"
              :field="field"
              :options="parent.children"></tree-options>
          </div>
        </div>
      </div>
    </div>
    <p class="help is-danger" v-if="local_value.unique !== undefined">
      <span v-if="local_value.exists == 1">
        {{ local_value.label }} vendosur eshte i zene!
      </span>
    </p>
    <p class="help is-danger"
      v-if="local_value.required && !local_value.selected_options && (!local_value.value || local_value == '')">
      {{ local_value.required_text ? local_value.required_text : "Fushe e detyrueshme" }}
    </p>
    <p class="help is-danger" v-if="local_value.required && Object.keys(local_value.selected_options)?.length == 0">
      {{ local_value.required_text ? local_value.required_text : "Fushe e detyrueshme" }}
    </p>

    <!-- {{local_value.inherited_selected_options}}  -->

    <ul v-if="local_value.inherited_selected_options?.length > 0" class="mb-3 border border-gray-200">
      <li  class="border border-gray-300 p-1 font-thin">Te trashguara</li>
      <li v-for="sel_option in local_value.inherited_selected_options" 
        class="ml-1"
        :key="sel_option.id">
        <i class="fa fa-check mr-2 text-green-500"></i>
        <span class="font-light text-indigo-800">
          {{
            sel_option.roots.split(' > ').length > 1 ? sel_option.roots.split(' > ').slice(1).reverse().join(' > ') : sel_option.roots
          }}:
        </span>

        {{ sel_option.attribute_name ? sel_option.attribute_name : '' }}
        {{ sel_option.accessory_name ? sel_option.accessory_name : '' }}
      </li>
    </ul>
    <!-- <pre> {{sorted_array}} </pre> -->
  </div>
</template>

<script>
import treeOptions from "./components/tree-option.vue";
import { ref, provide, reactive } from "vue";
export default {
  components: {
    treeOptions,
  },
  props: ["field", "fields"],
  setup() {
    const selectedvalue = ref({});
    const local_value = reactive({});
    provide("selectedvalue", selectedvalue);
    return {
      selectedvalue,
      local_value,
    };
  },
  data() {
    return {
      expand: false,
      label: "",
      search: "",
    };
  },

  methods: {
    collapse(p) {
      if (!p.children) {
        this.setSelected(p);
        return;
      }
      this.local_value = {
        ...this.local_value,
        options: [
          ...this.local_value.options.map((e) => {
            if (e.id == p.id) e.expand = !e.expand;
            return e;
          }),
        ],
      };
    },
    resetValue() {
      this.expand = !this.expand;
    },
    undoSelected() {
      for (let opt of Object.keys(this.local_value.selected_options)) {
        if (this.local_value.selected_options[opt])
          delete this.local_value.selected_options[opt]
        else
          this.$toast.error('Nuk ka opsion per te fshire!')
      }
    },
    setSelected(f) {
      var tmp = this.local_value;
      if (this.local_value.mode == "multiple") {
        if (!tmp.selected_options) tmp.selected_options = {};

        if (tmp.selected_options[f.id]) delete tmp.selected_options[f.id];
        else tmp.selected_options[f.id] = f.label;
        this.local_value = { ...tmp };
      } else {
        if (tmp.selected_options[f.id]) delete tmp.selected_options[f.id];
        else {
          tmp.selected_options = {};
          tmp.selected_options[f.id] = f.label;
        }
        this.local_value = { ...tmp };
      }
      this.$emit("syncField", this.local_value);
    },
  },
  computed: {
    condition() {
      var is_visible = true;
      if (this.local_value.vif) {
        if (this.fields)
          this.fields.map((f) => {
            if (f.length) {
              f.map((ff) => {
                this.local_value.vif.map((c) => {
                  if (ff.name == c.field) {
                    if (c.value_not !== undefined)
                      is_visible = ff.value != c.value_not;
                  }
                });
              });
            } else {
              this.local_value.vif.map((c) => {
                if (f.name == c.field) {
                  if (c.value_not !== undefined) {
                    is_visible = f.value != c.value_not;
                  }
                  if (c.value !== undefined) {
                    is_visible = f.value == c.value;
                  }
                }
              });
            }
          });
      }
      return is_visible;
    },
    sorted_array() {
      // Sort list
      var list
      var keys_values = Object.entries(this.local_value)
      console.log('this.local_value', this.local_value, keys_values)
      // list = unsortedArray.sort(([key]) => { })
      return list
    }
  },
  watch: {
    field: {
      handler: function (val) {
        this.local_value = { ...val };
      },
      deep: true,
    },
    /*'field'(){
        this.local_value = JSON.parse(JSON.stringify(this.field))	
      },*/
    selectedvalue() {
      if (this.selectedvalue != "") {
        this.setSelected({ ...this.selectedvalue });
        this.selectedvalue = "";
      }
    },
  },
  created() {
    this.local_value = { ...this.field };
  },
};
</script>

<style scoped>
.label {
  margin-bottom: 0 !important;
  color: grey;
}

.has-border {
  box-shadow: inset 0 0.0625em 0.125em hwb(0deg 4% 96% / 5%);
  border: solid 1px #dbdbdb;
}

.has-border-bottom {
  border-bottom: 1px solid #dbdbdb;
}
</style>
