<template>
  <div class="" style="height: 100%; display: flex; flex-direction: column;">
    <Navbar></Navbar>

    <div class=" p-0 container is-fluid root-class">
      <div class="is-flex" style="height:100%;">
        <Menu v-if="user_store.is_loggedin"></Menu>
        <div class="flex-1 p-2" style="width: calc(100vw - 40px);">
          <router-view :key="$route.params"></router-view>
        </div>
      </div>
    </div>
    <modals-container></modals-container>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useMenuStore } from '@/stores/menu'
import Api from './services/Api'
import { defineAsyncComponent } from 'vue'
import { useConfigurationsStore } from '@/stores/configurations'
import { ModalsContainer } from 'vue-final-modal'
export default {
  data() {
    return {
      user_store: storeToRefs(useUserStore()),
      menu_store: storeToRefs(useMenuStore()),
      modules: {
        customers: {
          title: "Kliente",
          icon: "fas fa-users",
          class: "bg-sky-500",
        },
        products: {
          title: "Produkte",
          icon: "fas fa-boxes",
          class: "bg-emerald-500 text-black",
        },
        accessories: {
          title: "Aksesore",
          icon: "fas fa-puzzle-piece",
          class: "bg-cyan-500 text-black",
        },
        warehouses: {
          title: "Magazine",
          icon: "fas fa-warehouse",
          class: "bg-blue-500 text-black",

        },
        suppliers: {
          title: "Furnitore",
          icon: "fas fa-truck",
          class: "bg-yellow-500 text-black",
        },
        employees: {
          title: "Punonjes"
        }
      }
    }
  },
  name: 'App',
  components: {
    Navbar: defineAsyncComponent(() => import('./layout/NavBar.vue')),
    // Menu: defineAsyncComponent( () => import('./components/Menu-backup-herbi.vue') ),
    Menu: defineAsyncComponent(() => import('./components/Menu.vue')),
    ModalsContainer
  },
  methods: {
    clearLocalStorage() {
      localStorage.removeItem("user")
      localStorage.removeItem("token")
    },
    gotoLogin() {
      this.user_store.session_check = 1
      this.user_store.is_loggedin = false
      this.$router.push({ path: '/login' })
    },
    setCurrentRoute(r) {
      this.menu_store.submenu = r
      this.menu_store.rootmenu = r.split('-')[0]
    }
  },
  /*watch:{
    'menu_store.submenu'(){
      // this.$router.push({path: this.menu_store.submenu})
      this.menu_store.rootmenu = this.menu_store.submenu.split('-')[0]
    }
  },*/
  computed: {
    currentRoutePath() {
      return this.$route.path;
    }
  },
  created() {
    if (localStorage.user != undefined) {
      this.user_store.session_check = -1
      Api(true).post('session/check')
        .then(r => {
          if (r.data == 1) {
            this.user_store.session_check = 1
            if (
              this.$route.path == "/login" ||
              this.$route.path == "/register"
            )
              this.$router.push({ path: '/home' })
            this.user_store.is_loggedin = true
            this.user_store.user = JSON.parse(localStorage.user)

            // Get configurations 

            useConfigurationsStore().getConfigs()
          }
        })
        .catch(() => {
          this.gotoLogin()
          this.clearLocalStorage()
        })
    }
    else {
      if (this.$route.path != "/login")
        this.gotoLogin()
      else {
        this.user_store.session_check = 1
        this.user_store.is_loggedin = false
      }
      this.clearLocalStorage()
    }
  }
}
</script>

<style>
/* @import url(https://maxcdn.bootstrapcdn.com/font-awesome/5.15.4/css/font-awesome.min.css); */
/* @import url('https://fonts.googleapis.com/css2?family=Karla:wght@300&family=Nunito+Sans:wght@300&family=Raleway:wght@200;700&family=Manrope:wght@200&family=Roboto+Condensed:wght@400;700&family=Squada+One&family=Varela&display=swap'); */

/* *{ */
/* 	font-family: 'Dosis', sans-serif, FontAwesome; */
/* } */
.button,
a {
  font-family: 'Prompt', sans-serif, FontAwesome;
}

select,
option {
  font-family: 'Prompt', sans-serif, FontAwesome;
}

.border-top {
  border-width: 1px;
  border-top-style: solid;
  /* padding-top: 20px; */
  border-color: #f5f5f5;
}

.is-grid {
  display: grid;
}

.group-report {
  text-align: center !important;
  vertical-align: middle !important;
}

.margin-auto {
  margin: auto
}

.clearfix {
  clear: both;
  overflow: auto;
}

.root-class {
  /* Backup working */
  /* min-height:calc(100vh - 48px);
	margin-bottom:0px!important; */

}

.navbar {
  min-height: 35px !important;
}

/* HRM General Styles */
  .tile-grid-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
  }

  /* Form stick on top when scroll down */
  .sticky-form{
    max-height: calc(100vh - 75px);
    overflow-y: auto;
    position: sticky;
    top: 0;
  }

  .toast {
    border: 1px solid #000
  }
</style>
