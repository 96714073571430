<template>
    <div>
        <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content">
            <button class="modal__close" @click="close">
                <i class="fa fa-times"></i>
            </button>
            <div class="modal__content">
                <a @click.prevent="create_city">
                    <i class="fa fa-plus"></i>
                    <span class="ml-3">Krijo Qytet</span>
                </a>
                 <DaForm
                    v-if="form_create"
                    @submitted="submitCity"
                    :form="form_create"
                 ></DaForm>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import createCity from "../forms/cities/create";
import CitiesServices from "@/services/City";
import { storeToRefs } from "pinia";
import { useCityStore } from "@/stores/city";
import DaForm from "@/components/daform/index.vue";
    export default {
        components: {
            VueFinalModal,
            DaForm
        },
        data() {
            return {
                form_create: null,
                cities: storeToRefs(useCityStore()).rowData
            }
        },
        methods: {
            create_city() {
                this.form_create = null
                this.form_create = createCity.form()
            },
            async submitCity(c) {
                if(this.form_create != null) {
                    this.cities.push(await CitiesServices.createCity(c))
                    this.form_create = null
                    this.$root.selected_one = {}
                }
            }
        },
        async created () {
            this.cities = await CitiesServices.getCities();
        },
    }
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
    padding: 10px;
    font-size: 22px;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>