import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'

export const useMenuStore = defineStore({
  id: 'menu',
  state: () => ({
		rootmenu: '', 
		submenu: '',
    show_menu_notifications: false,
    // notifications_list: {}
    notifications_list: localStorage.notifications_list ? JSON.parse(localStorage.notifications_list) : {},
  }),
  getters: {
    notification_count(){
      var count = 0
      Object.keys(this.notifications_list).map(key => {
        count += this.notifications_list[key]?.length
      })
      return count
    },
    visible_notifications(){
      var list = []
      Object.keys(this.notifications_list).map(key => {
        this.notifications_list[key]?.map(n => {
          n.type = key
          list.push(n)
        })
      })
      // Sort by date
      // list = list.slice().sort((a, b) => a.requested_at - b.requested_at)
      return list
    },
  }, 
  actions: {
    async open_notifications_list(){
      this.show_menu_notifications = !this.show_menu_notifications
      if(this.show_menu_notifications){
        await useUserStore().get_user_notifications()
      }
    },

  }
})
