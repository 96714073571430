import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './routes'
import Toaster from "@incuca/vue3-toaster";
import VueNumberFormat from 'vue-number-format'
import './index.css'
import './assets/tailwind.css'
import { vfmPlugin } from 'vue-final-modal'
import DaForm from "@/components/daform/index.vue";

import CKEditor from '@ckeditor/ckeditor5-vue';

const pinia = createPinia()
const app = createApp(App)


app.use(VueNumberFormat, {
  prefix: '',
  decimal: '.',
  thousand: ','
})
app.use(router)
app.use(CKEditor)
app.use(Toaster)
app.use(pinia)
app.use(vfmPlugin)
app.component('DaForm', DaForm)
app.config.globalProperties.$document = document

const clickOutside = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      // Check if the click was outside the element
      if (!(el === event.target || el.contains(event.target))) {
        // Call the provided method
        if (binding.value)
          binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    // Remove the event listener when the element is unmounted
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};

app.directive('click-outside', clickOutside);



app.mount('#app')
