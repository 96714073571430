<template>
  <div class="field" :style="field.style" v-if="condition">
    <label class="label">
      {{ local_value.label }}
      {{ local_value.required ? ' *' : '' }}
      <a href="" @click.prevent="$emit('reset', local_value)" v-if="field.updated"><i
          class="fa-solid fa-clock-rotate-left"></i></a>
    </label>
    <div class="control" :class="[
      local_value.left_icon ? 'has-icons-left' : '',
      local_value.right_icon ? 'has-icons-right' : ''
    ]">

      <div class="dropdown" :class="{ 'is-active': active }">
        <div class="dropdown-trigger">
          <button class="button is-small" aria-haspopup="true" aria-controls="dropdown-menu" @click="active = !active">
            <i :class="local_value.value"></i>
            <span class="icon is-small">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <a href="#" class="dropdown-item" @click.prevent="updateValue(icon)"
              v-for="(icon, idx) in listOfFreeFontawesomeIcons" :key="idx">
              <i :class="[icon, 'fa-2x', icon == local_value.value ? 'is-active' : '']"></i>
            </a>

          </div>
        </div>
      </div>


    </div>
    <p class="help is-danger" v-if="local_value.unique !== undefined">
      <span v-if="local_value.exists == 1">
        {{ local_value.label }} vendosur eshte i zene!
      </span>
    </p>
    <p class="help is-danger" v-if="local_value.required && (!local_value.value || local_value == '')">
      {{ local_value.required_text ? local_value.required_text : 'Fushe e detyrueshme' }}
    </p>
  </div>
</template>

<script>
export default {
  props: [
    'field', 'fields'
  ],
  data() {
    return {
      local_value: null,
      active: false,
      listOfFreeFontawesomeIcons: [
        // list of free fontawesome icons
        // only service icons are listed  
        // about paid services icons 

        // Accessibility
        'fas fa-accessible-icon',
        'fas fa-american-sign-language-interpreting',
        'fas fa-assistive-listening-systems',
        'fas fa-audio-description',
        'fas fa-blind',
        'fas fa-braille',
        'fas fa-closed-captioning',
        'fas fa-deaf',
        'fas fa-low-vision',
        'fas fa-phone-volume',
        'fas fa-question-circle',

        // reatail 
        'fas fa-shopping-cart',
        'fas fa-shopping-bag',
        'fas fa-shopping-basket',
        'fas fa-shopping-cart',
        'fas fa-store',
        'fas fa-store-alt',
        'fas fa-store-alt-slash',
        'fas fa-store-slash',
        'fas fa-tshirt',
        'fas fa-tags',
        'fas fa-tag',
        'fas fa-truck',
        'fas fa-truck-loading',
        'fas fa-truck-monster',
        'fas fa-truck-moving',
        'fas fa-truck-pickup',
        'fas fa-warehouse',
        'fas fa-box',
        'fas fa-box-open',
        'fas fa-boxes',
        'fas fa-box-tissue',
        'fas fa-cash-register',
        'fas fa-clipboard-check',
        'fas fa-clipboard-list',
        'fas fa-dolly',
        'fas fa-dolly-flatbed',
        'fas fa-donate',
        'fas fa-gift',
        'fas fa-hand-holding',
        'fas fa-hand-holding-heart',
        'fas fa-hand-holding-usd',
        'fas fa-hand-holding-water',
        'fas fa-hand-lizard',
        'fas fa-hand-paper',
        'fas fa-hand-peace',
        'fas fa-hand-point-down',
        'fas fa-hand-point-left',
        'fas fa-hand-point-right',
        'fas fa-hand-point-up',
        'fas fa-hand-pointer',
        'fas fa-hand-rock',
        'fas fa-hand-scissors',
        'fas fa-hand-spock',
        'fas fa-hands',
        'fas fa-hands-helping',
        'fas fa-handshake',
        'fas fa-handshake-alt',
        'fas fa-handshake-alt-slash',
        'fas fa-handshake-slash',
        'fas fa-luggage-cart',
        'fas fa-parachute-box',
        'fas fa-passport',
        'fas fa-piggy-bank',
        'fas fa-receipt',
        'fas fa-ribbon',
        'fas fa-route',
        'fas fa-sack-dollar',
        'fas fa-shopping-bag',
        'fas fa-shopping-basket',
        'fas fa-shopping-cart',
        'fas fa-suitcase',
        'fas fa-suitcase-rolling',
        'fas fa-tshirt',
        'fas fa-wallet',
        'fas fa-box',
        'fas fa-box-open',
        'fas fa-boxes',

        // web
        'fas fa-address-book',
        'fas fa-address-card',
        'fas fa-archive',
        'fas fa-balance-scale',
        'fas fa-ban',
        'fas fa-bath',
        'fas fa-bell',
        'fas fa-bell-slash',
        'fas fa-bicycle',
        'fas fa-binoculars',
        'fas fa-birthday-cake',
        'fas fa-bomb',
        'fas fa-book',
        'fas fa-bookmark',
        'fas fa-briefcase',
        'fas fa-bug',
        'fas fa-building',
        'fas fa-bullhorn',
        'fas fa-bullseye',
        'fas fa-bus',
        'fas fa-calculator',
        'fas fa-calendar',
        'fas fa-camera',
        'fas fa-car',
        'fas fa-caret-square-down',
        'fas fa-caret-square-left',
        'fas fa-caret-square-right',
        'fas fa-caret-square-up',
        'fas fa-chart-bar',
        'fas fa-check',
        'fas fa-check-circle',
        'fas fa-check-square',
        'fas fa-circle',
        'fas fa-clipboard',
        'fas fa-clock',
        'fas fa-clone',
        'fas fa-closed-captioning',
        'fas fa-cloud',
        'fas fa-comment',
        'fas fa-comment-alt',
        'fas fa-comments',

        // manufactory
        'fas fa-industry',

        // assembly
        'fas fa-cogs',
        'fas fa-cog',
        'fas fa-tools',
        'fas fa-screwdriver',
        'fas fa-hammer',
        'fas fa-wrench',

        // transport
        // not repeated 

      ]
    };
  },
  methods: {
    updateValue(value) {
      this.local_value.value = value
      this.active = false
      this.$emit('syncField', this.local_value)
    }
  },
  computed: {
    condition() {
      var is_visible = true
      if (this.local_value.vif) {
        if (this.fields)
          this.fields.map(f => {
            if (f.length) {
              f.map(ff => {
                this.local_value.vif.map(c => {
                  if (ff.name == c.field) {
                    if (c.value_not !== undefined)
                      is_visible = ff.value != c.value_not
                  }
                })
              })
            }
            else {
              this.local_value.vif.map(c => {
                if (f.name == c.field) {
                  if (c.value_not !== undefined) {
                    is_visible = f.value != c.value_not
                  }
                  if (c.value !== undefined) {
                    is_visible = f.value == c.value
                  }

                }
              })
            }
          })
      }
      return is_visible
    },
  },
  watch: {
    'field'() {
      this.local_value = { ...this.field }
    },

  },
  created() {
    this.local_value = { ...this.field }
  }
}
</script>

<style scoped>
.label {
  margin-bottom: 0 !important;
  color: grey;
}

/* modify the dropdown so it shows the items in 4 columns */
.dropdown-content {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-gap: 0.6rem;
  padding: 0.6rem;
}

/* make the dropdown items smaller */
.dropdown-item {
  font-size: 0.8rem;
  padding: 0.375rem 1rem;
}

.is-active {
  color: #229748;
}
</style>

