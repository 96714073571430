// configurations store with pinia 
import { defineStore } from 'pinia'
import configurations from '@/services/Configuration'


export const useConfigurationsStore = defineStore({
  id: 'configurations',
  state: () => ({
    configs: [],
  }),
  getters: {
    company_name: (state) => {
      return state.configs?.find(e => e.config_key == 'COMPANY_NAME')?.config_value
    }
  },
  actions: { 
    async getConfigs() {
    configurations.getConfigurations().then((res) => {
        this.configs = res
      })

    }
  }
})
