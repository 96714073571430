
import Api from "@/services/Api";

export default {
    async getConfigurations() {
        var configs = []
        await Api(true).post("configuration/list", {})
        .then(r => {
            configs = r.data
        })
        return configs
    },
    async getPaymentsConfiguration() {
        var payment_data = []
        await Api(true).post("configuration/payments/data", {})
        .then(r => {
            payment_data = r.data
        })
        return payment_data
    },
    async getBrandsList(){
        var brands = []
        await Api(true).post("configuration/brands", {})
        .then(r => {
            brands = r.data
        })
        return brands
    },
    async getRejectionReasons(){
        var rejection_reasons = []
        await Api(true).post("configuration/rejection/reasons/list", {})
        .then(r => {
            rejection_reasons = r.data
        })
        return rejection_reasons
    },

    async getEnergyEfficiency() {
        var energy_efficiency = []
        await Api(true).post("configuration/energy/efficiency/list", {})
        .then(r => {
            energy_efficiency = r.data
        })
        return energy_efficiency
    }

}