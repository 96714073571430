<template>
	<div class="field mb-2" :style="field.style" v-if="condition">
		<div class="is-flex attribute-field items-center">
			<label v-if="Object.keys(local_value.options).length > 0 || local_value.is_exclusive" class="label mb-0 mr-auto">
				{{local_value.label}}
				{{local_value.required ? ' *' : ''}}
			</label>
			<div v-if="show_exclusive_attributes_list" class="flex-1 ml-2">
				<div class="select is-small w-full">
					<select v-model="selectedToBeAddedAttribute">
            <option :value="null">Zgjidh atribut</option>
						<option v-for="attr in local_value.exclusive_options" 
              :key="attr" 
							:value="attr.id">{{attr.label}}</option>
					</select>
				</div>
			</div>
			<a v-if="local_value.is_exclusive" @click.prevent="start_add_exclusive_attributes()" href="" class="button is-small ml-2">
				<i v-if="show_exclusive_attributes_list && selectedToBeAddedAttribute" class="fa-solid fa-plus"></i>
				<i v-else class="fa-solid fa-eye"></i>
			</a>
		</div>
		<div>
			<div v-for="o in Object.keys(local_value.options)" 
				class="has-border h-30" 
				:key="o">
				<label :for="local_value.options[o].root.guid" 
					class="is-flex is-justify-content-space-between 
					is-align-items-center h-30">
					{{ local_value.options[o].root.name }}

				<input 
					:name="local_value.options[o].root.guid" style="width: 50%" 
					:class="[ 'input is-small' ]" 
					v-model="local_value.options[o].root.value" 
					:type="local_value.options[o].root.type" 
					v-if="['text', 'number'].includes(local_value.options[o].root.type) ">

				<input class="mr-6"
					:id="local_value.options[o].root.guid"
					:name="local_value.options[o].root.guid" 
					v-model="local_value.options[o].root.value" 
					true-value="1" false-value="0"
					:type="local_value.options[o].root.type" 
					v-else-if="['checkbox'].includes(local_value.options[o].root.type) ">

				<div class="control" v-else>
					<div :name="local_value.options[o].root.guid" class="select is-small"  >
						<select v-model="local_value.options[o].root.value">
							<option :value="c.guid" v-for="c in local_value.options[o].children" :key="c.guid" >
								{{c.name}}
							</option>	
						</select>
					</div>
				</div>

				</label>
			</div>
		</div>
		<p class="help is-danger" v-if="local_value.unique !== undefined">
			<span v-if="local_value.exists == 1">
				{{local_value.label}} vendosur eshte i zene!
			</span>
		</p>
		<p class="help is-danger" 
			v-if="local_value.required && (!local_value.value || local_value == '')">
			{{ local_value.required_text ? local_value.required_text : 'Fushe e detyrueshme' }}
		</p> 
	</div>
</template>

<script>
	// import Api from "@/services/Api";
	import helpers from "@/helpers.js";

	export default{
		props: [
			'field', 'fields'
		], 
		data() {
			return {
				local_value: null,
				show_exclusive_attributes_list: false,
				selectedToBeAddedAttribute: null
			};
		},
		methods: {
			start_add_exclusive_attributes(){
				if(this.selectedToBeAddedAttribute != null) {
					this.local_value = {
						...this.local_value, 
						options: {
							...this.local_value.options,
							...helpers.create_attributes_field(
								this.local_value.exclusive_options[this.selectedToBeAddedAttribute].attributes, 
								'EXCLUSIVE')
						},
					}
					this.local_value = JSON.parse(JSON.stringify(this.local_value))
					this.$emit('syncField', this.local_value)
          this.show_exclusive_attributes_list = false
				}else {
					this.show_exclusive_attributes_list = !this.show_exclusive_attributes_list
				}
			}
		},
		
		computed: {
			condition(){
				var is_visible = true
				if(this.local_value.vif){
				if(this.fields)
					this.fields.map(f => {
						if(f.length) {
							f.map(ff => {
								this.local_value.vif.map(c => {
									if(ff.name == c.field){
										if(c.value_not !== undefined) 
											is_visible = ff.value != c.value_not
									}
								})
							})
						}
						else {
								this.local_value.vif.map(c => {
									if(f.name == c.field) {
										if(c.value_not !== undefined) {
											is_visible = f.value != c.value_not
										}
										if(c.value !== undefined) {
											is_visible = f.value == c.value
										}

									}
								})
						}
					})
				}
				return is_visible
			},
		},
		watch: {
			'field'(){
				this.local_value = {...this.field}	
			},
      'local_value.value'(){
        this.$emit('syncField', this.local_value)
      }
		},
		created(){
			this.local_value = {...this.field}	
		}
	}
</script>

<style scoped>
.label {
    margin-bottom: 0!important;
		color: grey;
}
.has-border{
	border-bottom: 1px solid #ccc;
}
.h-30{
	height: 35px;
}

/* remove from last child */
.has-border:last-child{
  border-bottom: none;
}

.control{
  width: 50%;
}
</style>

