import Api from "@/services/Api";
import helpers from "@/helpers.js";

export default {
	async getCategories(entity, unit = null){
		var categories = [];
		await Api(true)
		.post("category/list/" + entity, {unit: unit})
		.then((response) => {
			categories = response.data.map((e) => {
				e.id = e.guid;
				e.label = e.name;
				e.collapsed = true;
				return e;
			}),
			"id"
		});
		return categories;
	},
	async getTreeCategories(flat_list, relation) {
		return helpers.toTree(flat_list, relation)
	},
	async getCategoryAttributes(category, entity, only_connected = null, related_entity = 'attributes'){
		var category_attributes = []
		await Api(true)
		.post("category/" + related_entity, {
			selected_options: category,
			entity: entity.guid,
			only_connected
		})
		.then((r) => {
			category_attributes = r.data
		});
		return category_attributes;
	},

  async getCompanyUnits(){
    var company_units = []
    await Api(true).post("company/units", {})
      .then((r) => {
        company_units = r.data
      })
    return company_units
  },

	async getAttributesList(parent, related_entity) {
        var attribute_list = []
        await Api(true).post("attribute/attribute_list", {parent_id: parent, related_entity: related_entity})
        .then(r => {
            attribute_list = r.data
        })
        return attribute_list
    },

	async getParentAttributeList(parent, related_entity, included) {
		var attribute_list = []
		await Api(true).post("attribute/parent_attribute_list", {parent_id: parent, 
			related_entity: related_entity, 
			included: included
		})
		.then(r => {
			attribute_list = r.data
		})
		return attribute_list
	},
	async getParentAccessoryList(parent, related_entity, included) {
		var accessories_list = []
		await Api(true).post("product/parent/accessories/list", {parent_id: parent, 
			related_entity: related_entity, 
			included: included
		})
		.then(r => {
			accessories_list = r.data
		})
		return accessories_list
	},
	// get all the relations of category / attribute
	async getEntityAttributes(related_entity) {
		var entity_attributes = []
		await Api(true).post("attribute/category/" + related_entity, {
			related_entity: related_entity
		})
		.then(r => {
			entity_attributes = r.data
		})
		return entity_attributes
	},
	// get all the relations of category / accessory
	async getEntityAccessories(related_entity) {
		var entity_accessories = []
		await Api(true).post("accessory/category/" + related_entity, {
			related_entity: related_entity
		})
		.then(r => {
			entity_accessories = r.data
		})
		return entity_accessories
	},
	async getCategoryProcess(category, mode, related_entity) {
		await Api(true).post(
			mode == "add"
			? "category/create/" + related_entity
			: "category/edit/" + related_entity,
			category
		)
		.then(r => {
			category = r.data
		})
		return category
	},
	async getServicesCategories(){
		var service_types = []
		await Api(true).post("service_types/get")
		.then(r => {
			service_types  = r.data
		})
		return service_types 

	},
	async getParentServiceTypeList(parent, included){
		// get parent service types
		var service_type_list =[]
		await Api(true).post("service_types/parent_category_list", {
			parent_id: parent,
			included: included
		}).then(res => {
			service_type_list = res.data
		})
		return service_type_list
	},
  async getParentPaymentTerms(parent, included){
    var payment_terms_list = []
    await Api(true).post("customer/category/"+parent+"/payment/terms", {
      parent_id: parent,
      included: included
    }).then(res => {
      payment_terms_list = res.data
    })
    return payment_terms_list
  }

}
