// helpers module
// functions to be used by vue components
import moment from "moment";


// ----------------------------------------------------------------------------
export default {
  // ----------------------------------------------------------------------------
  // Function that creates an attribute field for a form
  create_attributes_field(tmp_category_attribute, selected_category) {
    let options = {};
    tmp_category_attribute.map((d) => {
      if (!options[d.attribute_id])
        options[d.attribute_id] = {
          root: {
            guid: d.attribute_id,
            name: d.attribute_name,
            type: d.type_id,
            dependency: d.guid ?? selected_category,
            value: d.attribute_value,
          },
          children: [],
        };
      // if(options[d.attribute_id])
        options[d.attribute_id].children.push({
          guid: d.child_attribute_guid,
          name: d.child_attribute_name,
        });
    });
    return options;
  },
  // Function that creates a tree structure form a flat array
  toTree(dataset, identity) {
    const hashTable = Object.create(null);
    dataset.forEach((aData) => (hashTable[aData[identity]] = { ...aData }));
    const dataTree = [];
    dataset.forEach((aData) => {
      if (aData.parent_id) {
        if(hashTable[aData.parent_id]){
          if (!hashTable[aData.parent_id].children)
          hashTable[aData.parent_id].children = [];
          hashTable[aData.parent_id].children.push(hashTable[aData[identity]]);
          
          // if there is a sort_nr field sort the children by it
          if(hashTable[aData[identity]].sort_nr){
            hashTable[aData.parent_id].children.sort((a, b) => {
              return a.sort_nr - b.sort_nr;
            });
          }
        }
      } else dataTree.push(hashTable[aData.guid]);
    });
    return dataTree;
  },

  dateFormatter(date) {
    return moment(date?.value).format("DD/MM/YYYY HH:mm");
  },

  timeFormatter(date) {
    return moment(date.value).format("HH:mm");
  },
  
  // Print module
  get_html_table(product_table_columns, items, total_row){
    var replaced_table_chars = this.replace_table_chars_and_split(product_table_columns)
    var table = "<style>" +
    " .table-html .totals_column_name { font-size: 10px; text-align: left; text-transform: uppercase; font-weight: normal; }" + 
    " .table-html .right { text-align: right}" + 
    " .table-html .left { text-align: left}" + 
    " .table-html td, th { border: none !important; border-bottom: 0.01em solid #ccc !important;} " + 
    "</style>" 
    table += "<table class='table-html table' style='width:100%;'>"

    table += "<thead><tr>"
    for (let i = 0; i < replaced_table_chars.length; i++) {
      var sp = replaced_table_chars[i]
      var replaced_chars1 = this.replace_chars_and_split(sp)

      if(replaced_chars1[2]){
        var r = new RegExp("_", "g")
      }
      table += "<th class='left' style='" + (replaced_chars1[2]?.replace(r, ": ") ?? '') + "'>" + replaced_chars1[1] + "</th>"
    }
    table += "</tr></thead>"

    table += "<tbody>"
    var totals = {}
    // var price_total = 0
    // var qty_total = 0
    for (var j = 0; j < items.length; j++) {
      table += "<tr> "
      // if(replaced_table_chars.length > 0 && (replaced_table_chars[0] != '' && replaced_table_chars[0] != undefined)){
        for (var k = 0; k < replaced_table_chars.length; k++) {
            var sp1 = replaced_table_chars[k]
            var replaced_chars = this.replace_chars_and_split(sp1)
            if(replaced_chars[2]){
              var r1 = new RegExp("_", "g")
            }
            table += "<td style='" + (replaced_chars[2]?.replace(r1, ': ') ?? '') + "'>" + (items[j][replaced_chars[0]] ?? '<i>-</i>') + "</td>"
            if(replaced_chars.length > 3 ){
                if(replaced_chars[3] == '*'){
                  if(!totals[replaced_chars[0]]) totals[replaced_chars[0]] = {total: 0, name: replaced_chars[1]}
                  totals[replaced_chars[0]].total += parseFloat(items[j][replaced_chars[0]])
                }
            }
        }
      // }
        
        table += "</tr>"
    }

    table += "</tbody>"

    if(Object.keys(totals).length > 0){
      if(total_row){
        table +="<tfoot>"
        var iii = 0 
        Object.keys(totals).map(key => {
          table += "<tr>"
          if(iii == 0) table += "<th valign='top' class='left' colspan='"+(replaced_table_chars.length - 2)+"' rowspan='"+(Object.keys(totals).length) + "'>TOTAL</th>"
          table += "<th class='totals_column_name'>"+(totals[key].name ?? '')+"</th>"
          table += "<th class='right has-text-right'>"+(totals[key].total ?? '')+"</th>"
          table += "</tr>"
          iii++

        })
        table +="</tfoot>"
      } else {
        table += "<tfoot><tr>"
        for (let i = 0; i < replaced_table_chars.length; i++) {
          var sp2 = replaced_table_chars[i]
          var replaced_chars2 = this.replace_chars_and_split(sp2)
          if(i == 0) { 
            table += "<th class='left'>TOTAL</th>"
          }
          else table += "<th class='right'>" + (totals[replaced_chars2[0]] ? totals[replaced_chars2[0]].total : '') + "</th>"
        }
        if(!total_row) table += "</tr>"
        table += "</tr></tfoot>"
      }
    }


    table += "</table>"

    return table
  },
  replace_table_chars_and_split(str) {
      str = str.replace("[", "")
      str = str.replace("]", "")
      str = str.replace("' , ", "',")
      str = str.replace("' ,", "',")
      str = str.replace("', ", "',")
      str = str.split(",")
      return str
  },
  replace_chars_and_split(str){ 
    // str = str.replace("\n", "")
    var regex = new RegExp("'", 'g')
    str = str.replace(regex, '')
    var regex1 = new RegExp('\\n', 'g')
    str = str.replace(regex1, '')
    str = str.replace(" ", '')
    str = str.split(":")

    return str
  },
};
