import { defineStore } from 'pinia'
import Api from "@/services/Api";
import helpers from "@/helpers.js";

export const useJobStore = defineStore({
  id: 'job',
  state: () => ({
    service_types: [],
    process_document_service: [],
    process_jobs: [],
    // selected: '',
    is_new: true,
    selected_job: {},
    selected_services: [],
    service_type_filter: 'show_all',
    users_list: [],
    
    // Job list variables
    job_list:[],
    selected_single_job: {},
    loading_single_job: false
  }),
  getters: {
    multiple_select_mode(){
      var a = false
      if(this.selected_job.guid){
        a = true
      }
      // this.process_jobs.map(j => {
      // })
      return a

    }
  },
  actions: {
    async getServiceTypes(type, get_types = true) {
      // type = this.selected
      Api(true).post("processes/doc/services", { service_id: type, get_types: get_types })
        .then(res => {
          if(get_types == true)
          // Services types
          this.service_types = res.data['service_types']

          // process_document_service
          this.process_document_service = res.data['process_document_services'].map(s => {
            s.checked = false,
            s.new_requested_for = s.requested_for ? s.requested_for : '',
            s.edit_requested_for = false
            return s
          })

          // process_jobs
          this.process_jobs = res.data['process_jobs'].map(j => {
            j.collapsed = false
            j.collapse_loading = false
            return j
          })
          this.users_list = res.data['users_list']
        })
    },
    async add_as_job(service) {
      return await Api(true).post("processes/save/document/job", {service: service, is_new: this.is_new})
    },
    start_select_multiple(job){
      if(this.selected_job.guid && (this.selected_job.guid == job.guid)){
        this.selected_job = {}
        this.cancel_multiselect()
      }
      else {
        // Select the job
        this.selected_job = job
      }
    },
    cancel_multiselect(){
      // Empty services list
      this.selected_services = [...[]]
      // this.selected_job = {}
      // Incheck services
      this.process_document_service = [...this.process_document_service.map(ds => {
        ds.checked = false
        return ds
      })]
    },
    add_remove_service_to_list(doc_serv){
      // Check if already exists
      var found = false
      this.selected_services.map(ss => {
        if(ss.guid == doc_serv.guid){
          found = true
        }
      })
      if(!found){
        // Do the push
        this.selected_services.push(doc_serv)
      }
      else{
        // TODO: If found - Remove from list
        this.selected_services = this.selected_services.filter(aa => aa.guid != doc_serv.guid)
      }
      // Mark doc_job as checked ???
      this.process_document_service = this.process_document_service.map(ds => {
        if(ds.guid == doc_serv.guid){
          ds.checked = !ds.checked
        }
        return ds
      })
    },
    async add_multiple_services(){
      var toaster = ''
      await Api(true).post("processes/add/multiple/jobs", {
        job: this.selected_job, 
        services: this.selected_services
      }) .then(async res => {
        if(res.data.length > 0){
          // this.selected_job = {}
          // await this.getServiceTypes()
          
          // Push the result to the job
          this.process_jobs = this.process_jobs.map(j => {
            if(j.guid == this.selected_job.guid){
              j.document_services = res.data.map(rr => {
                rr.new_requested_for = rr.requested_for ? rr.requested_for : ''
                return rr
              })
            }
            return j
          })
          // Add to the service the selected_job guid
          this.process_document_service.map(ds => {
            this.selected_services.map(ss => {
              if(ds.guid == ss.guid){
                ds.job_id = this.selected_job.id
              }
            })
            return ds
          })
          
          
          await this.cancel_multiselect()
          toaster = 'success'
        } 
        else  
          toaster = 'error'
      })
      return {toaster}
    },
    async collapse_job(job){
      
      var get_data = false
      this.process_jobs = this.process_jobs.map(j =>{
        if(j.guid == job.guid){
          j.collapsed = !j.collapsed
          if(j.collapsed) 
          get_data = true
          j.collapse_loading = true
        }
        return j
      })
      if(get_data){
        // Get collapsed data of job
        await Api(true).post("processes/get/job/data", {
          job
        }).then(res => {
          this.process_jobs = this.process_jobs.map(j => {
            if(j.guid == job.guid){
              j.document_services = res.data.map(rr => {
                rr.new_requested_for = rr.requested_for ? rr.requested_for : ''
                return rr
              })
            }
            j.collapse_loading = false
            return j
          })
        })
      }
      
    },
    async get_job_services(job){
      return await Api(true).post("processes/get/job/data", { job })
    },
    async create_empty_job(){
        if(confirm("Krijo nje Flete pune te re?")){
          await Api(true).post("processes/create/empty/job", { })
          .then(r => {
            if(r.data > 0){
              this.getServiceTypes()
            }
          })
          
        }
    },
    async edit_service_job(job, service){
          var return_data = []
          await Api(true).post("processes/edit/service/job", { 
            job: job.id ? job : 0, 
            service }).then(res => {
              if(res.data.message == "service_removed_success"){
                // Remove job_id from services in the right
                this.process_document_service = this.process_document_service.map(pds => {
                  if(pds.guid == service.guid){
                    pds.job_id = null
                  }
                  return pds 
                })
                // Remove service from the job document_services list
                // Removed in return
              }
              else if(res.data.message == 'service_updated_success') {
                // Updatye all service list
                this.process_document_service = this.process_document_service.map(pds => {
                  if(pds.guid == res.data.updated_service[0].guid){
                    pds = {...res.data.updated_service[0]}
                  }
                  return pds 
                })
                // update service of job...
                this.process_jobs = this.process_jobs.map(j => {
                  if(j.guid == job.guid){
                    j.document_services = j.document_services.map(ds => {
                      if(ds.guid == service.guid){
                        res.data.updated_service[0].new_requested_for = res.data.updated_service[0].requested_for ? res.data.updated_service[0].requested_for : ''
                        res.data.updated_service[0].edit_requested_for = false
                        ds = res.data.updated_service[0]
                        // console.log('here>?', ds, res.data.updated_service[0])
                      }
                      return ds
                    })
                  }
                  return j
                })
                
              }
              
              return_data = res.data
            })
            return return_data
          },
          async get_job_services_types(){
            
            await Api(true).post("processes/get/job/service/types", { })
            .then(r => {
        this.service_types = r.data
      })
    },
    replace_table_chars_and_split(str) {
      str = str.replace("[", "")
      str = str.replace("]", "")
      str = str.replace("' , ", "',")
      str = str.replace("' ,", "',")
      str = str.replace("', ", "',")
      str = str.split(",")
      
      return str
    },
    
    async get_job_services_print(list){
      return await Api(true).post("processes/job/services/print", { list })
    },
    
    
    // prepare / collect the printing variables / keys / parameters
    get_print_html(content, job, services){
      // console.log('j', job, services)
      // Mbledhjen e te dhenave
      var keys = {
        '@job_id': job.id,
        '@job.created_at': job.created_at,
        '@job.user_name': job.user_name
      }
      if(content.includes('@service.list')){
        var services_table_columns = this.getSubstringBetween(content, "@service.list", "@service.list.end")
        var orgsubs = services_table_columns
        var has_total_row = content.indexOf("@total_row");
        if(has_total_row > -1) content = content.replace("@total_row", "")
        content = content.replace("@service.list", "")
        content = content.replace("@service.list.end", "")
        content = content.replace(orgsubs, "@table")
        keys["@table"] = helpers.get_html_table(services_table_columns, services, has_total_row > -1)
      }

      return this.print_job_replace_keys(keys, content)
    },
    replace_chars_and_split(str){
      // str = str.replace("\n", "")
      var regex = new RegExp("'", 'g')
      str = str.replace(regex, '')
      var regex1 = new RegExp('\\n', 'g')
      str = str.replace(regex1, '')
      str = str.replace(' ', '')
      str = str.split(":")
      return str
    },
    print_job_replace_keys(keys, content){
      Object.keys(keys).map(k => {
        var regex = new RegExp(k, 'g');
        content = content.replace( regex, keys[k])
      })
      return content
    },

    getSubstringBetween(originalString, startSubstring, endSubstring) {
      var startIndex = originalString.indexOf(startSubstring);
      if (startIndex === -1) {
        return '';
      }
      
      startIndex += startSubstring.length;
      
      var endIndex = originalString.indexOf(endSubstring, startIndex);
      if (endIndex === -1) {
        return '';
      }
      
      return originalString.substring(startIndex, endIndex);
    },
    
    // Job List functions
    async update_job(job){
      return await Api(true).post("job/update", {job})
    },
    get_job_list_data(){
      Api(true).post("job/list/data")
      .then(r => {
        this.job_list = r.data.job_list
      })
      
    },

    async select_single_job(job){
      this.selected_single_job = job
      
      this.loading_single_job = true
      await Api(true).post("processes/get/job/data", {
        job
      }).then(res => {
        this.selected_single_job.document_services = res.data
      })
      this.loading_single_job = false
    },
    async change_doc_service_status(service, job){
      return await Api(true).post("job/change/service/status", {service, job})
    },
    close_job(job) {
      if (confirm("Jeni te sigurt qe doni te perfundoni kete proces?")) {
        return Api(true).post("processes/job/close", {job})
      }
    },
    async process_job(job) {
      return await Api(true).post("job/process/job", {job})
    }
    // get_job_services
  }
})
