import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'

export default function auth () {
	const user = storeToRefs(useUserStore())
	if(!user.is_loggedin.value && user.session_check.value != -1){
		return {
			name: 'login'
		}
	}
}
