<template>
	<div class="field" :style="field.style" v-if="condition">
		<label class="label">
			{{local_value.label}}
			{{local_value.required ? ' *' : ''}}
		</label>
		<div class="control"
			:class="[
				local_value.left_icon ? 'has-icons-left' : '',
				local_value.right_icon ? 'has-icons-right' : ''
			]">
			<div class="select is-small is-fullwidth" v-if="!field.disabled">
				<select v-if="local_value.options_group " 
						name="local_value.name" id="local_value.name" 
						@change="$emit('syncField', local_value)"
						v-model="local_value.value">
					<optgroup v-for="type in Object.keys(options_group)" :key="type" :label="get_option_group_label(type)">
						<option v-for="element in options_group[type]" :key="element.id"
							:value="element.id">{{element.label}}</option>
					</optgroup>
				</select>
				<select  v-else
						name="local_value.name" id="local_value.name" 
						@change="$emit('syncField', local_value)"
						v-model="local_value.value">
          <option value="">{{local_value.placeholder || 'Zgjidh...'}}</option>
					<option v-for="element in local_value.options" :key="element.id"
						:value="element.id">{{element.label}}</option>
				</select>
				<!-- <pre> {{options_group}} </pre> -->
			</div>
			<div v-else class="has-border">
				{{local_value.disabled_text}}
			</div>
		</div>
		<p class="help is-danger" v-if="local_value.unique !== undefined">
			<span v-if="local_value.exists == 1">
				{{local_value.label}} vendosur eshte i zene!
			</span>
		</p>
		<p class="help is-danger" 
			v-if="local_value.required && (!local_value.value || local_value == '')">
			{{ local_value.required_text ? local_value.required_text : 'Fushe e detyrueshme' }}
		</p> 
	</div>
</template>

<script>
	export default{
		props: [
			'field', 'fields'
		], 
		data() {
			return {
				local_value: null
			};
		},
		methods: {
			get_option_group_label(o){
				var label = ''
				this.local_value.options.map(a => {
					if(a[this.local_value.options_group] == o){
						label = a[this.local_value.options_group_label]
						return
					}
				})
				return label
			}
		},
		
		computed: {
			condition(){
				var is_visible = true
				if(this.local_value.vif){
				if(this.fields)
					this.fields.map(f => {
						if(f.length) {
							f.map(ff => {
								this.local_value.vif.map(c => {
									if(ff.name == c.field){
										if(c.value_not !== undefined) 
											is_visible = ff.value != c.value_not
										if(c.value !== undefined) 
											is_visible = ff.value == c.value
									}
								})
							})
						}
						else {
								this.local_value.vif.map(c => {
									if(f.name == c.field) {
										if(c.value_not !== undefined) {
											is_visible = f.value != c.value_not
										}
										if(c.value !== undefined) {
											is_visible = f.value == c.value
										}

									}
								})
						}
					})
				}
				return is_visible
			},
			options_group(){
				var roles_list = {}
				this.local_value.options.map(a => {
					if(!roles_list[a[this.local_value.options_group]])
						roles_list[a[this.local_value.options_group]] = []
					roles_list[a[this.local_value.options_group]].push(a)
				})
				return roles_list
			}
		},
		watch: {
			'field'(){
				this.local_value = {...this.field}	
			},

		},
		created(){
			this.local_value = {...this.field}	
		}
	}
</script>

<style scoped>
.label {
    margin-bottom: 0!important;
		color: grey;
}
.has-border {
	border: 1px solid #dbdbdb;
	padding: 3px;
}
</style>

