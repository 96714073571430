
import Api from "@/services/Api";
export default{

	async getSuppliers(){
		let suppliers = []	
        await Api(true) .post("supplier/list", {})
        .then((response) => {
            suppliers = response.data.map((c) => {
                c.id = c.guid
                c.label = c.name
                return c;
            });
        });
        return suppliers;
	},

    async createSuppliers(supplier) {
        await Api(true).post("supplier/create", supplier)
        .then(r => {
            supplier = r.data
        })
        return supplier
    },

    async editSuppliers(supplier) {
        await Api(true).post("supplier/edit", supplier)
        .then(r => {
            supplier = r.data
        })
        return supplier
    },
}