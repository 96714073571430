<template>
	<div class="field" :style="field.style" v-if="condition">
		<label class="label">
			{{local_value.label}}
			{{local_value.required ? ' *' : ''}}
			<a href="" @click.prevent="$emit('reset', local_value)" v-if="field.updated"><i class="fa-solid fa-clock-rotate-left"></i></a>
		</label>
		<div class="control"
			:class="[
				local_value.left_icon ? 'has-icons-left' : '',
				local_value.right_icon ? 'has-icons-right' : ''
			]">
			<input class="input is-small" :class="[field.updated ? 'is-warning' : '']" 
				type="text" @change="$emit('syncField', local_value)" 
				:name="local_value.name" v-model="local_value.value"/>
			<span class="icon is-small is-left" 
					v-if="local_value.left_icon">
				<i :class="local_value.left_icon"></i>
			</span>
			<span class="icon is-small is-right" 
					v-if="local_value.right_icon">
				<i :class="local_value.right_icon"></i>
			</span>
		</div>
		<p class="help is-danger" v-if="local_value.unique !== undefined">
			<span v-if="local_value.exists == 1">
				{{local_value.label}} vendosur eshte i zene!
			</span>
		</p>
		<p class="help is-danger" 
			v-if="local_value.required && (!local_value.value || local_value == '')">
			{{ local_value.required_text ? local_value.required_text : 'Fushe e detyrueshme' }}
		</p> 
	</div>
</template>

<script>
	export default{
		props: [
			'field', 'fields'
		], 
		data() {
			return {
				local_value: null,
                file_name: ''
			};
		},
        methods: {
            onFile(e) {
                const files = e.target.files
                if(!files.length) return
                
                this.file_name = files[0].name
                this.local_value.value = this.file_name
            }
        },
		
		computed: {
			condition(){
				var is_visible = true
				if(this.local_value.vif){
				if(this.fields)
					this.fields.map(f => {
						if(f.length) {
							f.map(ff => {
								this.local_value.vif.map(c => {
									if(ff.name == c.field){
										if(c.value_not !== undefined) 
											is_visible = ff.value != c.value_not
									}
								})
							})
						}
						else {
								this.local_value.vif.map(c => {
									if(f.name == c.field) {
										if(c.value_not !== undefined) {
											is_visible = f.value != c.value_not
										}
										if(c.value !== undefined) {
											is_visible = f.value == c.value
										}

									}
								})
						}
					})
				}
				return is_visible
			},
		},
		watch: {
			'field'(){
				this.local_value = {...this.field}	
			},

		},
		created(){
			this.local_value = {...this.field}	
		}
	}
</script>

<style scoped>
.label {
    margin-bottom: 0!important;
		color: grey;
}
</style>

