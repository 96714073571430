import { defineStore } from 'pinia'
import Api from "@/services/Api";

export const useReservationStore = defineStore({
  id: 'reservation',
  state: () => ({
    reservation_list: [],
    search_val: '',
    current_page: 1,
    items_per_page: 100,

  }),
  getters: {
    reservation_filtered() {
      return this.reservation_list.filter(document => JSON.stringify(document).toLowerCase().includes(this.search_val.toLowerCase())).slice((this.current_page - 1) * this.items_per_page, this.current_page * this.items_per_page)
    },
    total_pages() {
      return Math.ceil(this.reservation_filtered.length / this.items_per_page)
    },

  }, 
  actions: {
    async get_reservations() {
      await Api(true).post("/reservation/get/data")
      .then(res => {
        this.reservation_list = res.data
      })
    },
    change_page(page) {
      this.current_page = page
    },

    async handover(reservation) {
      var toaster = {}
      if (confirm("Jeni te sigurt qe doni dorezoni kete porosi?") == true) {
        await Api(true).post("/reservation/handover", reservation)
        .then(res => {
          if (res.data > 0) {
            this.reservation_list = this.reservation_list.map(a => {
              if (a.guid == reservation.guid) {
                a.completed_status = 1
              }
              return a
            })
            toaster.message = 'success'
          }
        })
      }
      return toaster
    }
  }
})